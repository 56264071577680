export const MenuList = [
  {
    title: "DAO Voting",
    url: "DAOVoting",
  },
  {
    title: "STAKING",
    url: "/Staking",
  },
  {
    title: "INVENTORY",
    url: "/Inventory",
  },
  {
    title: "TRAIT SWAP",
    url: "/Trait_Swap",
  },
  {
    title: "SHOP",
    url: "/Shop",
  }
];