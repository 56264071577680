import React, { useState, useEffect, useRef } from 'react'; 
import {filter, utils} from "ethers";
import Card from "../../Utils/Cards/Card"
import CardsStaked from '../../Utils/Cards/CardStaked';
import { Button } from 'react-bootstrap'
import "./Voting.css"
import { getCurrentWalletConnected, walletConnectWallet, clearWalletConnectWallet } from "./../../Utils/Wallet/Interact";
import {Redirect, Route} from "react-router-dom";
import { getConnectedStakingContract, getConnectedERC721Contract, getConnectedERC20Contract } from '../../Utils/Contracts/ContractConnections';
//import coin from "./../../Images/coin.png"
import coin from "./../../Images/cashnipcoin.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Snackbar from "./../../Utils/Snackbar/Snackbar";
import {ProposalContainer} from "./ProposalContainer.jsx"

require('dotenv').config()
AOS.init();

const SnackbarType = {
    success: "success",
    fail: "fail",
};


const Voting = () => {
    const snackbarRef = useRef(null);
    const [snackBarType, SetSnackBarType] = useState(SnackbarType.fail)
    const [snackBarMessage, SetSnackBarMessage] = useState("")

    const [displayWallet, setDisplayWallet] = useState("");
    const [proposals, setProposals] = useState([]);
    const [logBackIn, setLogBackIn] = useState(false);
    const [showInfo, setShowInfo] = useState("none");

    var wallet2 = ""
    var votePower = 0

    const { toChecksumAddress } = require('ethereum-checksum-address')

    useEffect(async () => {
        wallet2 = await (await getCurrentWalletConnected()).address
        setDisplayWallet(wallet2)
        getProposals()
    }, []);


    async function getProposals(){
        let proposalArray = "https://nudies.s3.us-west-1.amazonaws.com/Proposals/Config.json"

        try {
            fetch(proposalArray)
            .then((response) => response.json())
            .then((actualData) => renderProposalBodies(actualData))
            .catch((err) => {
                console.log(err.message);
            });

        } catch (err) {
            console.log(err)
        }
    }

    async function renderProposalBodies(data){
        //console.log(data)
        let proposals = []

        for (let i = 0; i < data.length; i++) {
            proposals.push(data[i])    
        }

        setProposals(proposals)
    }

    async function LogBackIn(){
        setLogBackIn(true)
        wallet2 = null;
    }

    function hideInfoBox(){
        setShowInfo("none")
    }

    function showInfoBox(){
        setShowInfo("block")
    }

    return(
    <div className='votingPage'>
            <Snackbar
                ref={snackbarRef}
                message={snackBarMessage}
                type={snackBarType}
            />
        <div className='infoPannelBK' style={{
                display:showInfo
            }}>      
            <div className='infoPannel'>
                <div className='infoPannelHeader'>
                    <h1>DAO Voting Information</h1>
                    <button onClick={hideInfoBox} className='closeInfo fa fa-times'>
                    </button>
                </div>
                <div className='infoPannelFooter'>
                    <div className='stakeInfo'>
                        <p>You may cast a vote for each proposal for the given options, your vote power is equal to your owned / staked Nudies.</p>
                        <p>Voting will terminate when the shown date / time is reached.</p>
                        <p>Click "Show Results" to view any vote tallies. Click the arrow wheel icon to refresh results. Please allow up to 15 seconds to see new votes when refreshing</p>
                    </div>
                </div>
            </div>
        </div>
        <div data-aos="fade-left" className='topContainer'>
            <Button className='walletConnected' onClick={LogBackIn}>
                {displayWallet.length > 0 ? (
                String(displayWallet).substring(0, 4) +
                "..." +
                String(displayWallet).substring(39)
                ) : (
                <span>Wallet Error</span>
                )}
            <Route exact path="/DAOVoting" render={() => (
                logBackIn ? (
                    <Redirect to="/"/>
                ) : (
                    <Redirect to="/DAOVoting"/>
                )
                )}/>
            </Button>
            <Button className='walletConnected' onClick={showInfoBox}>
                Voting Info
            </Button>
        </div>
            <ProposalContainer proposalData={proposals} votingPower={votePower}></ProposalContainer>
    </div>
    )
}

export default Voting;
