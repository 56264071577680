import {ethers, BigNumber} from "ethers";
import React, {useState } from "react";
import NudieStakingAbi from "./NudieStakingAbi.json";
import NudieERC721Abi from "./NudieERC721Abi.json";
import NudieERC20Abi from "./NudieERC20Abi.json";

const NudieERC721 = "0x89Bd11D1fBa7906464fa11f3086fE8ba1B5368A1";
const NudieStaking = "0x8Dd465DF3D1d4E0a4ae978FA7fb30Eb558AB8714";
const NudieERC20 = "0x1240a63A39EFAFaD3433A7B95B9E19f062B67E23";
//const NudieERC20 = "0x0C294b7958c8B5A189b1DaB2D8367D21422F10C1";


export const getConnectedStakingContract = async () => {
    const {ethereum} = window;
    if(!ethereum) return;

    const provider = new ethers.providers.Web3Provider(
      ethereum
    );

    const signer = provider.getSigner();

    const connectedContract =
      new ethers.Contract(
        NudieStaking,
        NudieStakingAbi.abi,
        signer
    )

    return connectedContract
}

export const getConnectedERC721Contract = async () => {
    const {ethereum} = window;
    if(!ethereum) return;

    const provider = new ethers.providers.Web3Provider(
      ethereum
    );

    const signer = provider.getSigner();

    const connectedContract =
      new ethers.Contract(
        NudieERC721,
        NudieERC721Abi.abi,
        signer
    )
    return connectedContract
}

export const getConnectedERC20Contract = async () => {
    const {ethereum} = window;
    if(!ethereum) return;

    const provider = new ethers.providers.Web3Provider(
      ethereum
    );

    const signer = provider.getSigner();

    const connectedContract =
      new ethers.Contract(
        NudieERC20,
        NudieERC20Abi.abi,
        signer
    )
    return connectedContract
}