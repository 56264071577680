import React, { useState, useEffect, useRef } from 'react'; 
import { Button } from 'react-bootstrap'
import "./Shop.css"
import { getCurrentWalletConnected, walletConnectWallet, clearWalletConnectWallet } from "./../../Utils/Wallet/Interact";
import {Redirect, Route} from "react-router-dom";
import { getConnectedStakingContract, getConnectedERC721Contract, getConnectedERC20Contract } from '../../Utils/Contracts/ContractConnections';
//import coin from "./../../Images/coin.png"
import coin from "./../../Images/cashnipcoin.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Snackbar from "./../../Utils/Snackbar/Snackbar";
import {ItemSection} from "./ItemSection"
import {ItemSectionArray} from "./ItemSectionArray"
import logo from "../../Images/nudieLogo.png"
import ShopLoadingSpinner from "./../../Utils/LoadingSpinner/ShopLoadingPage"
import { ClosetInitialization } from "./../Inventory/ClosetInitialization";
import allowedWallets from "./../allowedWallets.json"

require('dotenv').config()
AOS.init();

const APIKEY1 = process.env.REACT_APP_KEY1;

const SnackbarType = {
    success: "success",
    fail: "fail",
};


const Shop = () => {
    const snackbarRef = useRef(null);
    const [snackBarType, SetSnackBarType] = useState(SnackbarType.fail)
    const [snackBarMessage, SetSnackBarMessage] = useState("")

    const [walletAmount, setWalletAmount] = useState(0)

    const [wallet, setWallet] = useState()//getCurrentWalletConnected());
    const [displayWallet, setDisplayWallet] = useState("");

    const [shopItems, setShopItems] = useState([])
    const [shopItemsFeatured, setShopItemsFeatured] = useState([])
    const [shopItemsBackground, setShopItemsBackground] = useState([])
    const [shopItemsSkins, setShopItemsSkins] = useState([])
    const [shopItemsClothing, setShopItemsClothing] = useState([])
    const [shopItemsAccessories, setShopItemsAccessories] = useState([])
    const [shopItemsEyes, setShopItemsEyes] = useState([])
    const [shopItemsMouth, setShopItemsMouth] = useState([])
    const [shopItemsHeadwear, setShopItemsHeadwear] = useState([])

    const [initFilter, setInitFilter] = useState(true)

    const [chosenShopItem, setChosenShopItem] = useState([])

    const [logBackIn, setLogBackIn] = useState(false);
    const [showInfo, setShowInfo] = useState("none");

    const { toChecksumAddress } = require('ethereum-checksum-address')

    const [isShopLoading, setShopIsLoading] = useState(false);

    var wallet2 = ""

    // const [hideAll, setHideAll] = useState("none")
    // useEffect(async () => {
    //     let wallet = await (await getCurrentWalletConnected()).address
    //     let matched = false

    //     for (let i = 0; i < allowedWallets.length; i++) {
    //         if(toChecksumAddress(wallet) == allowedWallets[i].walletID){
    //             matched = true
    //             setHideAll("block")
    //         }
    //     }
    // }, []);

    useEffect(async () => {
        wallet2 = await (await getCurrentWalletConnected()).address
        if(!wallet2){wallet2 = await (await walletConnectWallet()).address}
        setDisplayWallet(wallet2)        
        const interval = setInterval(() => {
            getWalletAmount()
            .catch((err) => {
                //console.log(err.message);
            });
          }, 2000);
        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        RetrieveShopItems()
    }, [])

    async function getWalletAmount(){
        const connectedERC20Contract = await getConnectedERC20Contract()
        wallet2 = await (await getCurrentWalletConnected()).address
        try{      
            if(!connectedERC20Contract) return;
            {
                try {
                    const walletERC20Total = await connectedERC20Contract.balanceOf(wallet2)
                    const number = (parseInt(walletERC20Total.toHexString(16), 16) / 1000000000000000000)
                    setWalletAmount(number);                    
                } catch (error) {   
                }
            }
          } catch (err){
            //console.log(err);
          }
    }

    var shopItemArray = []

    const RetrieveShopItems = async () => {
        var shopConfig = "https://nudies.s3.us-west-1.amazonaws.com/Shop/Config.json"

        try {
            fetch(shopConfig)
            .then((response) => response.json())
            .then((actualData) => loadShopItems(actualData))
            .catch((err) => {
                //console.log(err.message);
            });

        } catch (err) {
            //console.log(err)
        }
    }

    const loadShopItems = (actualData) =>{
        var shopFeaturedItemArray = []
        var shopBackgroundItemArray = []
        var shopSkinsItemArray = []
        var shopClothingItemArray = []
        var shopAccessoriesItemArray = []
        var shopEyesItemArray = []
        var shopMouthItemArray = []
        var shopHeadwearItemArray = []

        if(actualData.length != null){
            for(var i = 0; i < actualData.length; i++){
                if(actualData[i].featured == "true" || actualData[i].featured == true){
                    shopFeaturedItemArray.push(
                        {
                            "ID": actualData[i].ID,
                            "itemSKU" : actualData[i].itemSKU,
                            "featured" : actualData[i].featured,
                            "trait_type": actualData[i].trait_type,
                            "value": actualData[i].value,
                            "name": actualData[i].name,
                            "imageUrl" : actualData[i].imageUrl,
                            "price" : actualData[i].price,     
                            "quantity" : actualData[i].quantity             
                        }
                    )   
                }

                switch (actualData[i].trait_type) {
                    case "Background":
                        shopBackgroundItemArray.push(
                            {
                                "ID": actualData[i].ID,
                                "itemSKU" : actualData[i].itemSKU,
                                "featured" : actualData[i].featured,
                                "trait_type": actualData[i].trait_type,
                                "value": actualData[i].value,
                                "name": actualData[i].name,
                                "imageUrl" : actualData[i].imageUrl,
                                "price" : actualData[i].price,     
                                "quantity" : actualData[i].quantity             
                            }
                        )                 
                        break;
                    case "Skins":
                        shopSkinsItemArray.push(
                            {
                                "ID": actualData[i].ID,
                                "itemSKU" : actualData[i].itemSKU,
                                "featured" : actualData[i].featured,
                                "trait_type": actualData[i].trait_type,
                                "value": actualData[i].value,
                                "name": actualData[i].name,
                                "imageUrl" : actualData[i].imageUrl,
                                "price" : actualData[i].price,     
                                "quantity" : actualData[i].quantity             
                            }
                        )   
                        break; 
                    case "Clothing":
                        shopClothingItemArray.push(
                            {
                                "ID": actualData[i].ID,
                                "itemSKU" : actualData[i].itemSKU,
                                "featured" : actualData[i].featured,
                                "trait_type": actualData[i].trait_type,
                                "value": actualData[i].value,
                                "name": actualData[i].name,
                                "imageUrl" : actualData[i].imageUrl,
                                "price" : actualData[i].price,     
                                "quantity" : actualData[i].quantity             
                            }
                        )                         
                        break; 
                    case "Costumes":
                        shopClothingItemArray.push(
                            {
                                "ID": actualData[i].ID,
                                "itemSKU" : actualData[i].itemSKU,
                                "featured" : actualData[i].featured,
                                "trait_type": actualData[i].trait_type,
                                "value": actualData[i].value,
                                "name": actualData[i].name,
                                "imageUrl" : actualData[i].imageUrl,
                                "price" : actualData[i].price,     
                                "quantity" : actualData[i].quantity             
                            }
                        )                         
                        break; 
                    case "Accessories":
                        shopAccessoriesItemArray.push(
                            {
                                "ID": actualData[i].ID,
                                "itemSKU" : actualData[i].itemSKU,
                                "featured" : actualData[i].featured,
                                "trait_type": actualData[i].trait_type,
                                "value": actualData[i].value,
                                "name": actualData[i].name,
                                "imageUrl" : actualData[i].imageUrl,
                                "price" : actualData[i].price,     
                                "quantity" : actualData[i].quantity             
                            }
                        )                 
                        break; 
                    case "Body Additions":
                        shopAccessoriesItemArray.push(
                            {
                                "ID": actualData[i].ID,
                                "itemSKU" : actualData[i].itemSKU,
                                "featured" : actualData[i].featured,
                                "trait_type": actualData[i].trait_type,
                                "value": actualData[i].value,
                                "name": actualData[i].name,
                                "imageUrl" : actualData[i].imageUrl,
                                "price" : actualData[i].price,     
                                "quantity" : actualData[i].quantity             
                            }
                        )                 
                        break; 
                    case "Eyes":
                        shopEyesItemArray.push(
                            {
                                "ID": actualData[i].ID,
                                "itemSKU" : actualData[i].itemSKU,
                                "featured" : actualData[i].featured,
                                "trait_type": actualData[i].trait_type,
                                "value": actualData[i].value,
                                "name": actualData[i].name,
                                "imageUrl" : actualData[i].imageUrl,
                                "price" : actualData[i].price,     
                                "quantity" : actualData[i].quantity             
                            }
                        )                 
                        break; 
                    case "Mouth":
                        shopMouthItemArray.push(
                            {
                                "ID": actualData[i].ID,
                                "itemSKU" : actualData[i].itemSKU,
                                "featured" : actualData[i].featured,
                                "trait_type": actualData[i].trait_type,
                                "value": actualData[i].value,
                                "name": actualData[i].name,
                                "imageUrl" : actualData[i].imageUrl,
                                "price" : actualData[i].price,     
                                "quantity" : actualData[i].quantity             
                            }
                        )                 
                        break; 
                    case "Hair":
                        shopHeadwearItemArray.push(
                            {
                                "ID": actualData[i].ID,
                                "itemSKU" : actualData[i].itemSKU,
                                "featured" : actualData[i].featured,
                                "trait_type": actualData[i].trait_type,
                                "value": actualData[i].value,
                                "name": actualData[i].name,
                                "imageUrl" : actualData[i].imageUrl,
                                "price" : actualData[i].price,     
                                "quantity" : actualData[i].quantity             
                            }
                        )                 
                        break; 
                    case "Headgear":
                        shopHeadwearItemArray.push(
                            {
                                "ID": actualData[i].ID,
                                "itemSKU" : actualData[i].itemSKU,
                                "featured" : actualData[i].featured,
                                "trait_type": actualData[i].trait_type,
                                "value": actualData[i].value,
                                "name": actualData[i].name,
                                "imageUrl" : actualData[i].imageUrl,
                                "price" : actualData[i].price,     
                                "quantity" : actualData[i].quantity             
                            }
                        )                 
                        break; 
                    case "Masks":
                        shopHeadwearItemArray.push(
                                {
                                    "ID": actualData[i].ID,
                                    "itemSKU" : actualData[i].itemSKU,
                                    "featured" : actualData[i].featured,
                                    "trait_type": actualData[i].trait_type,
                                    "value": actualData[i].value,
                                    "name": actualData[i].name,
                                    "imageUrl" : actualData[i].imageUrl,
                                    "price" : actualData[i].price,     
                                    "quantity" : actualData[i].quantity             
                                }
                            )                 
                            break; 
                    case "Hats":
                        shopHeadwearItemArray.push(
                                {
                                    "ID": actualData[i].ID,
                                    "itemSKU" : actualData[i].itemSKU,
                                    "featured" : actualData[i].featured,
                                    "trait_type": actualData[i].trait_type,
                                    "value": actualData[i].value,
                                    "name": actualData[i].name,
                                    "imageUrl" : actualData[i].imageUrl,
                                    "price" : actualData[i].price,     
                                    "quantity" : actualData[i].quantity             
                                }
                            )                 
                            break;    
                    default:
                        break;
                }                     
            }      
        }
        setShopItemsFeatured(shopFeaturedItemArray)
        setShopItemsBackground(shopBackgroundItemArray)
        setShopItemsSkins(shopSkinsItemArray) 
        setShopItemsClothing(shopClothingItemArray)
        setShopItemsAccessories(shopAccessoriesItemArray)
        setShopItemsEyes(shopEyesItemArray)
        setShopItemsMouth(shopMouthItemArray)  
        setShopItems(shopFeaturedItemArray)   
        setShopItemsHeadwear(shopHeadwearItemArray)                                                
    }

    // const loadShopItems = (actualData) =>{
    //     ////console.log(actualData)

    //     if(actualData.length != null){
    //         for(var i = 0; i < actualData.length; i++){
    //             shopItemArray.push(
    //                 {
    //                     "ID": actualData[i].ID,
    //                     "itemSKU" : actualData[i].itemSKU,
    //                     "featured" : actualData[i].featured,
    //                     "trait_type": actualData[i].trait_type,
    //                     "value": actualData[i].value,
    //                     "name": actualData[i].name,
    //                     "imageUrl" : actualData[i].imageUrl,
    //                     "price" : actualData[i].price,     
    //                     "quantity" : actualData[i].quantity             
    //                 }
    //             )         
    //         }      
    //         //setShopItems([...shopItems, chosenTrait])

    //     }
    //     // if(actualData.shopItems.length != null){
    //     //     for(var i = 0; i < actualData.shopItems.length; i++){
    //     //         shopItemArray.push(
    //     //             {
    //     //                 "ID": actualData.shopItems[i].ID,
    //     //                 "itemSKU" : actualData.shopItems[i].itemSKU,
    //     //                 "featured" : actualData.shopItems[i].featured,
    //     //                 "trait_type": actualData.shopItems[i].trait_type,
    //     //                 "value": actualData.shopItems[i].value,
    //     //                 "name": actualData.shopItems[i].name,
    //     //                 "imageUrl" : actualData.shopItems[i].imageUrl,
    //     //                 "price" : actualData.shopItems[i].price,     
    //     //                 "quantity" : actualData.shopItems[i].quantity             
    //     //             }
    //     //         )         
    //     //     }      
    //     //     //setShopItems([...shopItems, chosenTrait])

    //     // }
    //     setShopItems(shopItemArray)            

    // }


    async function LogBackIn(){
        setLogBackIn(true)
        wallet2 = null;
        //await clearWalletConnectWallet();
    }

    function hideInfoBox(){
        setShowInfo("none")
    }

    function showInfoBox(){
        setShowInfo("block")
        //console.log(chosenShopItem)
    }

    function checkboxChange(obj) {
        var checkbox = document.getElementsByClassName("shopCheckbox");
        for (var i = 0; i < checkbox.length; i++) {
            checkbox[i].checked = false;
        }
        obj.target.checked = true;
        
        let filterLayer = obj.target.id;
        FilterShopItems(filterLayer)
    }

    function FilterShopItems(filterLayer){
        setInitFilter(false)
        switch (filterLayer) {
            case "featuredCheckbox":
                setInitFilter(true)
                setShopItems(shopItemsFeatured)               
                break;
            case "backgroundCheckbox":
                setShopItems(shopItemsBackground)
                break; 
            case "skinsCheckbox":
                setShopItems(shopItemsSkins)
                break; 
            case "clothingCheckbox":
                setShopItems(shopItemsClothing)
                break;
            case "headwearCheckbox":
                setShopItems(shopItemsHeadwear)
                break;  
            case "accessoriesCheckbox":
                setShopItems(shopItemsAccessories)    
                break; 
            case "eyesCheckbox":
                setShopItems(shopItemsEyes)    
                break; 
            case "mouthCheckbox":
                setShopItems(shopItemsMouth)      
                break;  

            default:
                break;
        }
    }

    const shopPurchaseOngoing = (
        <div>
        </div>
    )

    const [displayInventoryBox, setDisplayInventoryBox] = useState("none")

    async function setUpCloset(){
        setDisplayInventoryBox("block")
    }

    useEffect(async () => {
        RetrieveClosetItems();
    }, [])

    const RetrieveClosetItems = async () => {
        var wallet2 =  await (await getCurrentWalletConnected()).address
        var closetUrl = "https://nudies.s3.us-west-1.amazonaws.com/Closet/" + toChecksumAddress(wallet2) + ".json"
        //console.log(closetUrl)
        try {
            fetch(closetUrl)
            .then((response) => response.json())
            .catch((err) => {
                //console.log(err);
                setUpCloset();
            });

        } catch (err) {
            //console.log(err)
        }
    }

      return(
        // <div className='comingSoon'>
        //     <h1>COMING SOON</h1>    
        // </div>
        <div className='shopPage'>
                <Snackbar
                    ref={snackbarRef}
                    message={snackBarMessage}
                    type={snackBarType}
                />
            {isShopLoading ? <ShopLoadingSpinner style={{display:"none"}} /> : shopPurchaseOngoing}
            <div className='infoPannelBK' style={{
                    display:showInfo
                }}>      
                <div className='infoPannel'>
                    <div className='infoPannelHeader'>
                        <h1>Shop Information</h1>
                        <button onClick={hideInfoBox} className='closeInfo fa fa-times'>
                        </button>
                    </div>
                    <div className='infoPannelFooter'>
                        <div className='stakeInfo'><p>Purchased items will be put into your wallets closet.</p><p> To apply your 
                        new items, please head to the inventory section of this site.</p>
                        <p>After paying your tokens, you will need to sign a message to confirm your purchases, please do not decline this or you may loose your item.</p>
                        </div>
                    </div>
                </div>
            </div>
            <ClosetInitialization prop2={displayInventoryBox} handler={setDisplayInventoryBox}/>
            <div data-aos="fade-left" className='topContainer'>
                <div className='walletContainer'>
                    {Math.round(walletAmount)}<img src={coin}/>
                    {/* {walletAmount}<img src={coin}/> */}
                </div>
                <Button className='walletConnected' onClick={LogBackIn}>
                    {displayWallet.length > 0 ? (
                    String(displayWallet).substring(0, 4) +
                    "..." +
                    String(displayWallet).substring(39)
                    ) : (
                    <span>Wallet Error</span>
                    )}
                <Route exact path="/Staking" render={() => (
                    logBackIn ? (
                        <Redirect to="/"/>
                    ) : (
                        <Redirect to="/Staking"/>
                    )
                    )}/>
                </Button>
                <Button className='walletConnected' onClick={showInfoBox}>
                    Shop Info
                </Button>
                <div className='shopFilterContainer'>
                    <div className='shopCheckboxContainer'>
                        <h2>Featured</h2>
                        <input className='shopCheckbox' type="checkbox" id='featuredCheckbox' onClick={checkboxChange}></input>
                    </div>
                    <div className='shopCheckboxContainer'>
                        <h2>Background</h2>
                        <input className='shopCheckbox' type="checkbox" id='backgroundCheckbox' onClick={checkboxChange}></input>
                    </div>
                    <div className='shopCheckboxContainer'>
                        <h2>Skins</h2>
                        <input className='shopCheckbox' type="checkbox" id='skinsCheckbox' onClick={checkboxChange}></input>
                    </div>
                    <div className='shopCheckboxContainer'>
                        <h2>Clothing</h2>
                        <input className='shopCheckbox' type="checkbox" id='clothingCheckbox' onClick={checkboxChange}></input>
                    </div>
                    <div className='shopCheckboxContainer'>
                            <h2>Headwear</h2>
                            <input className='shopCheckbox' type="checkbox" id='headwearCheckbox' onClick={checkboxChange}></input>
                    </div>
                    <div className='shopCheckboxContainer'>
                        <h2>Accessories</h2>
                        <input className='shopCheckbox' type="checkbox" id='accessoriesCheckbox' onClick={checkboxChange}></input>
                    </div>
                    <div className='shopCheckboxContainer'>
                        <h2>Eyes</h2>
                        <input className='shopCheckbox' type="checkbox" id='eyesCheckbox' onClick={checkboxChange}></input>
                    </div>
                    <div className='shopCheckboxContainer'>
                        <h2>Mouth</h2>
                        <input className='shopCheckbox' type="checkbox" id='mouthCheckbox' onClick={checkboxChange}></input>
                    </div>
                </div>
            </div>
            <div className='shopFilterMobileContainer'>
                    <div className='shopCheckboxContainer'>
                        <h2>Featured</h2>
                        <input className='shopCheckbox' type="checkbox" id='featuredCheckbox' onClick={checkboxChange} checked={initFilter}></input>
                    </div>
                    <div className='shopCheckboxContainer'>
                        <h2>Background</h2>
                        <input className='shopCheckbox' type="checkbox" id='backgroundCheckbox' onClick={checkboxChange}></input>
                    </div>
                    <div className='shopCheckboxContainer'>
                        <h2>Skins</h2>
                        <input className='shopCheckbox' type="checkbox" id='skinsCheckbox' onClick={checkboxChange}></input>
                    </div>
                    <div className='shopCheckboxContainer'>
                        <h2>Clothing</h2>
                        <input className='shopCheckbox' type="checkbox" id='clothingCheckbox' onClick={checkboxChange}></input>
                    </div>
                    <div className='shopCheckboxContainer'>
                        <h2>Accessories</h2>
                        <input className='shopCheckbox' type="checkbox" id='accessoriesCheckbox' onClick={checkboxChange}></input>
                    </div>
                    <div className='shopCheckboxContainer'>
                        <h2>Eyes</h2>
                        <input className='shopCheckbox' type="checkbox" id='eyesCheckbox' onClick={checkboxChange}></input>
                    </div>
                    <div className='shopCheckboxContainer'>
                        <h2>Mouth</h2>
                        <input className='shopCheckbox' type="checkbox" id='mouthCheckbox' onClick={checkboxChange}></input>
                    </div>
            </div>
            
            <div className='ShopContainer'>
                {/* <ItemSectionArray shopItemArray={shopItems} chosenShopItemHandler={setChosenShopItem}/> */}
                {/* <ItemSectionArray shopItemArray={shopItems}/> */}
                <ItemSectionArray shopItemArray={shopItems} setShopIsLoading={setShopIsLoading}/>
            </div>
            <div  className='bottomContainer'>

            </div>
            <div className='footer '>
            </div>
        </div>
    )
}

export default Shop;
