import "./TraitSwap.css"
import React, { useState, useEffect, useRef } from 'react'; 
import { Button } from 'react-bootstrap'
import { getCurrentWalletConnected, walletConnectWallet, clearWalletConnectWallet } from "../../Utils/Wallet/Interact";
import CardTraits from "../../Utils/Cards/CardTraits"


export const ClosetSelectorBubble = ({prop2, handler, setAddedTraitList}) =>{
    const { toChecksumAddress } = require('ethereum-checksum-address')

    const [chosenTraitImage, setChosenTraitImage] = useState([])
    const [chosenTraitArray, setChosenTraitArray] = useState([])
    const [tempTrait, setTempTrait] = useState([])

    const [amountTraitsSelected, setAmountTraitsSelected] = useState(0)
    const [chosenTrait, setChosenTrait] = useState()
    const [chosenLayer, setChosenLayer] = useState()

    const [showInventoryBox, setShowInventoryBox] = useState(prop2);

    var traitImagesArray = []
    var addedTraitImagesArray = []

    function hideRemoveInventoryBox(){
        handler("none")
        setShowInventoryBox("none")
    }
  
    useEffect(async () => {
        setShowInventoryBox(prop2)
        setAddedTraitList(chosenTraitArray)
    });

    useEffect(async () => {
        RetrieveClosetItems();
    }, [])

    const RetrieveClosetItems = async () => {
        var wallet2 =  await (await getCurrentWalletConnected()).address
        var closetUrl = "https://nudies.s3.us-west-1.amazonaws.com/Closet/" + toChecksumAddress(wallet2) + ".json"

        try {
            fetch(closetUrl)
            .then((response) => response.json())
            .then((actualData) => loadUserClosetItems(actualData))
            .catch((err) => {
                console.log(err.message);
            });

        } catch (err) {
            console.log(err)
        }
    }

    const loadUserClosetItems = (actualData) =>{
        if(actualData.attributes.length != null){
            for(var i = 1; i < actualData.attributes.length; i++){
                traitImagesArray.push(
                    {
                        "traitType": actualData.attributes[i].trait_type,
                        "value": actualData.attributes[i].value,
                        "imageUrl": actualData.attributes[i].image_url,                        
                    }
                )         
            }      
            setChosenTraitImage(traitImagesArray)            
        }
    }

    function returnCheckBoxes(){
        const allChecked = document.querySelectorAll('input[name=traitCheckboxes]:checked');
        const checkedValues = Array.from(allChecked).map(checkbox => checkbox.value);
        console.log(checkedValues)

        for (let i = 0; i < checkedValues.length; i++) {
            //traitReturnValue()
        }
    }

    useEffect (() => {
        if(chosenTraitArray.indexOf(chosenTrait) > -1 == false && chosenTrait != null){
            setChosenTraitArray([...chosenTraitArray, chosenLayer, chosenTrait])
            setAmountTraitsSelected(amountTraitsSelected + 1) 
        }
        //console.log("New updated input value:", chosenTraitArray)

    },[chosenTraitArray, tempTrait])

    async function addSelectedTrait(){
        setTempTrait([...tempTrait, chosenLayer, chosenTrait])
    }
    
    return(
        <div className='infoPannelBK' style={{
            display:showInventoryBox
        }}> 
        <div className='inventoryPannel'>
            <div className='inventoryPannelHeader'>
                <h1>Inventory Items</h1>
                <button onClick={hideRemoveInventoryBox} className='closeInfo fa fa-times'>
                </button>
            </div>
            <div className='NudieTraitsBubblePannelSubSections'>
                <div className='NudieTraitsBubbleHolder'>
                    <CardTraits handler={setChosenTrait} handler2={setChosenLayer} props={traitImagesArray}/>   
                </div>
            </div>
            <div className='NudieTraitsBubblePannelFooter'>
                <div className="NudieTraitsBubbleSelectedAmount">{amountTraitsSelected} Selected</div>
            </div>
            <div className='NudieTraitsBubblePannelFooter'>
                <Button className='removeSelectedTraitButton' onClick={addSelectedTrait}>Add Selected</Button>
            </div>
        </div>
    </div>
    )
}