import { getConnectedStakingContract, getConnectedERC721Contract, getConnectedERC20Contract } from '../Contracts/ContractConnections';
require('dotenv').config()
const APIKEY1 = process.env.REACT_APP_KEY1;

export const fetchOwnCollection = async (walletAddress) => {
    const WALLET_ADDRESS = walletAddress;

    const options = {
    method: 'GET',
    headers: {
        Accept: 'application/json',
        'X-API-KEY': APIKEY1,
    },
    };

    const collectionResponse = await fetch(
    `https://api.opensea.io/api/v1/collections?asset_owner=${WALLET_ADDRESS}`,
    options,
    ).then(response => response.json())
    .catch((err) => {
        console.log(err.message);
    });;

    //console.log(collectionResponse)

    if(collectionResponse.length > 0){
        //console.log("run")

        const collection = collectionResponse.map(item => ({
            details: item.description,
            slug: item.slug,
            name: item.name,
            contractAddress: "0x89Bd11D1fBa7906464fa11f3086fE8ba1B5368A1",
            owned: [],
        }));

    
        for (const iterator of collection) {
        const assetsResponse = await fetch(
            `https://api.opensea.io/api/v1/assets?owner=${WALLET_ADDRESS}&asset_contract_address=0x89Bd11D1fBa7906464fa11f3086fE8ba1B5368A1&limit=100&include_orders=false`,
            options,
        ).then(response => response.json())
        .catch((err) => {
            console.log(err.message);
        });
    
        iterator.owned = assetsResponse.assets
            .map(item => ({
            name: item.name,
            img: item.image_url,
            id: item.token_id,
            }))
            .filter(item => item.name && item.img);
        }
        var ownerTokenIDs = []

        for (let p = 0; p < collection[0].owned.length; p++) {
            ownerTokenIDs[p] = collection[0].owned[p].id;
        }
    }
    return ownerTokenIDs;
};


export async function loadStakedNudies(){
    var stakedImagesArray = []

    try{
        const connectedStakingContract = await getConnectedStakingContract()
        if(!connectedStakingContract) return;
            const stakedNudies = await connectedStakingContract.returnStakedNudies()
            for(var i = 0; i < stakedNudies.length; i++){
                stakedImagesArray.push(
                    {
                        "ID": parseInt(stakedNudies[i].toHexString(16), 16),
                        "Image": "https://nudies.s3.us-west-1.amazonaws.com/Assets/img/" + parseInt(stakedNudies[i].toHexString(16), 16) + ".png",
                        "Colour": "",                        
                    }
                )         
            }  
    }catch (err){
        console.log(err)
    }
    return stakedImagesArray
}

