import {Requests} from "./Requests"
import "./../TraitSwap.css"

export const RequestsArray = ({requests}) => {


    return(
        <div className="proposalRow">
            {requests.map(function(items, index){
                return (<Requests 
                        key={index}
                        GUUID={items.GUUID}
                        RequestFromNumber={items.SwapNudieFrom}
                        RequestTradeItems={items.InventoryItems}
                        RequestTradeTrait_type={items.SelectedLayer}
                        RequestTradeValue={items.SelectedValue}
                        OwnedNumber={items.SwapNudieToo}
                        OwnedTrait_type={items.SelectedSwapeeLayer}
                        OwnedValue={items.SelectedSwapeeValue}
                    />)
                })}
        </div>
    )
}