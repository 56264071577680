import "./Voting.css"
import React, { useState, useEffect, useRef } from 'react'; 
import SignMessageInput from "../../Utils/Blockchain/SignMessage"
import {SendAPIPost} from "../../Utils/APIs/BackEndAPIs"
import {ProposalButtonArray} from "./ProposalButtonArray"
import { getCurrentWalletConnected } from "./../../Utils/Wallet/Interact";
import LoadingSpinner from "./../../Utils/LoadingSpinner/LoadingSpinner";
import {ShowResults} from "./ShowResults"
import refresh from "./../../Images/refresh.png"

export const Proposals = ({ID, title, body, startTime, endTime, answers}) =>{
    
    var votePower = 0
    var imagesArray = []
    var nudieTradedArray = []
    var wallet = ""
    const [hideVote, setHideVote] = useState("none")
    const [endVoting, setEndVoting] = useState("none")
    const [showVotingEnded, setShowVotingEnded] = useState("none")
    const [showUserVoted, setShowUserVoted] = useState("none")
    const [showUserNoNudies, setShowUserNoNudies] = useState("none")
    const [userVoted, setUserVoted] = useState(false)

    const { toChecksumAddress } = require('ethereum-checksum-address')
    const [votingPower, setVotingPower] = useState([]);
    const [voteEndDate, setVoteEndDate] = useState();
    
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [proposalVotes, setProposalVotes] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [showResultsDisplay, setShowResultsDisplay] = useState(false)
    const [minHeight, setMinHeight] = useState(0)
    const [updateResults, setUpdateResults] = useState(false);

    var votesArray = []

    useEffect(() => {
        RetrieveVotes()
    }, [])

    useEffect(() => {
        if(updateResults == true){
            RetrieveVotes()
            setUpdateResults(false)
        }
    })
    
    const RetrieveVotes = async () => {
        var votesJson = "https://nudies.s3.us-west-1.amazonaws.com/Proposals/Votes.json"
    
        try {
            fetch(votesJson)
            .then((response) => response.json())
            .then((actualData) => loadVotes(actualData))
            .catch((err) => {
                console.log(err.message);
            });
    
        } catch (err) {
            console.log(err)
        }
    }
    
    const loadVotes = (actualData) =>{
        if(actualData.length != null){
            for(var i = 0; i < actualData.length; i++){
                votesArray.push(
                    {
                        "ID": actualData[i].ID,
                        "title" : actualData[i].itemSKU,
                        "votes" : actualData[i].votes            
                    }
                )         
            }      
        }
        // for (let j = 0; j < votesArray[ID - 1].votes.length; j++) {
        //     if(votesArray[ID - 1].votes[j].vote.length > 0){
        //         count = count + 1
        //         console.log(count)
        //     }
        // }
        //console.log(votesArray[0].votes[0].count)
        setProposalVotes(votesArray)  
    }

    useEffect(async () => {
        if(userVoted){
            setHideVote("none")
            setShowUserVoted("block")
        }
        //console.log("ID:" + ID + " " + hideVote)
    });

    useEffect(async () => {
        wallet = await (await getCurrentWalletConnected()).address
        if(Date.now() < (endTime * 1000)){
            getAllowedVotesOfUser(wallet)
        }
    }, []);

    useEffect(async () => {
        setIsLoading(true);
        let endDate = new Date(endTime * 1000).toLocaleString()
        setVoteEndDate(endDate)
    }, []);

    useEffect(async () => {
        if(Date.now() > (endTime * 1000)){
            setEndVoting("none")
            setShowVotingEnded("block")
            setHideVote("none")
            setIsLoading(false)
        }
    });

    async function getVoteConfig(votePower){
        fetch("https://nudies.s3.us-west-1.amazonaws.com/Proposals/Votes.json")
        .then((response) => response.json())
        .then((actualData) => checkIfVoted(actualData, votePower))
        .catch((err) => {
            console.log(err.message);
        });
    }

    async function checkIfVoted(data, votePower){
        var wallet = toChecksumAddress(await (await getCurrentWalletConnected()).address)
        var matched = false
        for (let i = 0; i < data.length; i++) {
            if(title == data[i].title){
                for (let j = 0; j < data[i].votedWallets.length; j++) {
                    if(data[i].votedWallets[j] == wallet){
                        setHideVote("none")

                        if(Date.now() < (endTime * 1000)){
                            setShowUserVoted("block")
                        }

                        matched = true
                    }    
                }                 
            }
        }
        if(matched == false && votePower > 0){setHideVote("unset")}
    }

    async function setChosenItem(){
        const JSONString = JSON.stringify({ 
            function: "BuyShopItem"})

        const singedResponse = await SignMessageInput(JSONString)

        SendAPIPost(singedResponse)
    }

    async function getAllowedVotesOfUser(wallet, matched){
        fetch("https://api.etherscan.io/api?module=account&action=tokennfttx&contractaddress=0x89Bd11D1fBa7906464fa11f3086fE8ba1B5368A1&address=" + wallet + "&offset=100&startblock=0&endblock=27025780&sort=asc&apikey=T7U7YX32H4KYIBBJ1NT6BVJWZHWQQ7NY4B")
        .then((response) => response.json())
        .then((actualData) => loadStakedNudiesCount(actualData, matched))
        .catch((err) => {
            setTimeout(() => {  getAllowedVotesOfUser() }, 2000);
            console.log(err.message);
        });
    }

    async function loadStakedNudiesCount(actualData, matched){
        let match = false;
        let matchFromStaker = false;
        const loadWallet = wallet;
        var nudieMatchArray = []

        //console.log(actualData)

        try{
            for (let j = 0; j < actualData.result.length; j++) {
                if(actualData.result[j].from == loadWallet || toChecksumAddress(actualData.result[j].from.toString()) == loadWallet){
                    for (let o = j; o < actualData.result.length; o++) {

                        if(actualData.result[o].from == "0x8dd465df3d1d4e0a4ae978fa7fb30eb558ab8714" || actualData.result[o].to == "0x8dd465df3d1d4e0a4ae978fa7fb30eb558ab8714"){
                            if(actualData.result[o].tokenID == actualData.result[j].tokenID){
                                matchFromStaker = true;                                
                            }
                        }

                        if(actualData.result[o].to == loadWallet || toChecksumAddress(actualData.result[o].to.toString()) == loadWallet){
                            if(actualData.result[o].tokenID == actualData.result[j].tokenID){
                                matchFromStaker = true;                                
                            }                        
                        }
                    }
                    if(matchFromStaker == false){
                        nudieTradedArray.push(actualData.result[j].tokenID)
                    }
                    matchFromStaker = false
                } 
            }   

            for(var i = 0; i < actualData.result.length; i++){
                match = false;
                for (let l = 0; l < nudieMatchArray.length; l++) {
                    if(nudieMatchArray[l] == actualData.result[i].tokenID) {
                        match = true;
                    }  
                }                  
                if(match == false){

                    if (nudieTradedArray.indexOf(actualData.result[i].tokenID) == -1)
                        {
                            imagesArray.push(
                                {
                                    "ID": actualData.result[i].tokenID,
                                    "Image": "https://nudies.s3.us-west-1.amazonaws.com/Assets/img/" + actualData.result[i].tokenID + ".png",
                                    "Colour": "",                        
                                }
                            )  
                        }
                }
                nudieMatchArray.push(actualData.result[i].tokenID)
            }
        }catch(err){
            console.log(err)
        }
        votePower = imagesArray.length

        if(votePower == 0){
            //console.log(votePower)
            setShowUserNoNudies("block")
            setHideVote("none")
        }else if(votePower > 0 && matched == false){
            setHideVote("unset")
        }
        await getVoteConfig(votePower)
        setVotingPower(votePower)
        setIsLoading(false)
    }

    async function showResultsBox(){
        await RetrieveVotes().then(
            setTimeout(() => {
                // if(showResultsDisplay == "none"){
                //     setShowResultsDisplay("block")
                // }else{
                //     setShowResultsDisplay("none")
                // }
                setShowResults(current => !current)
            }, 200)
        )
        RetrieveVotes()
        setShowResultsDisplay(!showResultsDisplay)
    }

    async function refreshResults(){
        if(showResultsDisplay){
            setShowResults(current => !current)
        }
        await RetrieveVotes()
        if(showResultsDisplay){
            setTimeout(() => {
                // if(showResultsDisplay == "none"){
                //     setShowResultsDisplay("block")
                // }else{
                //     setShowResultsDisplay("none")
                // }
                setShowResults(current => !current)
            }, 500)
        }
    }
// / style={{display:showResultsDisplay, minHeight:"200px"}}
    const renderAnswers = (
    <div>
        <div className="proposalVotingContainer" id="openVote" style={{display : hideVote}}>
            <div>
                <h3>Voting Power: {votingPower} NCN</h3>
                <ProposalButtonArray title={title} voteAnswers={answers} allowedVotes={votingPower} userVoted={setUserVoted} updateResult={setUpdateResults}></ProposalButtonArray>
                <h3>Voting Ends: {voteEndDate}</h3>
                <div className="showResultsButtonContainer">
                    <div className="topLevelButtons">
                        <button className="showResultsButton" onClick={showResultsBox}>Show Results</button>
                        <button className="refreshButton" onClick={refreshResults}><img src={refresh}/></button>
                    </div>
                    <div>
                        {showResults && <ShowResults 
                            ID={ID}
                            title={title}
                            answers={answers}
                            votes={proposalVotes}
                            handler={setShowResults}
                            boxIsShow={setShowResultsDisplay}/>
                        }
                    </div>
                </div>
            </div>
        </div>
        <div className="proposalVotingContainer" style={{display : showVotingEnded}}>
            <div className="voteEnded">
                <h3>Voting Has Ended</h3>
                <div className="showResultsButtonContainer">
                    <button className="showResultsButton" onClick={showResultsBox}>Show Results</button>
                    {showResults && <ShowResults 
                        ID={ID}
                        title={title}
                        answers={answers}
                        votes={proposalVotes}
                        handler={setShowResults}
                        boxIsShow={setShowResultsDisplay}/>
                    }
                </div>
            </div>
        </div>
        <div className="proposalVotingContainer" style={{display : showUserVoted}}>
            <div>
                <h3>Wallet Voted</h3>
                <h3>Voting Ends: {voteEndDate}</h3>
                <div className="showResultsButtonContainer">
                    <div className="topLevelButtons">
                        <button className="showResultsButton" onClick={showResultsBox}>Show Results</button>
                        <button className="refreshButton" onClick={refreshResults}><img src={refresh}/></button>
                    </div>
                    {showResults && <ShowResults 
                        ID={ID}
                        title={title}
                        answers={answers}
                        votes={proposalVotes}
                        handler={setShowResults}
                        boxIsShow={setShowResultsDisplay}/>
                    }
                </div>
            </div>
        </div>
        <div className="proposalVotingContainer" style={{display : showUserNoNudies}}>
            <div>
                <h3>Wallet Owns No Nudies</h3>
                <h3>Voting Ends: {voteEndDate}</h3>
                <div className="showResultsButtonContainer">
                    <button className="showResultsButton" onClick={showResultsBox}>Show Results</button>
                    {showResults && <ShowResults 
                        ID={ID}
                        title={title}
                        answers={answers}
                        votes={proposalVotes}
                        handler={setShowResults}
                        boxIsShow={setShowResultsDisplay}/>
                    }
                </div>
            </div>
        </div>
    </div>
    )

    return(
        <div className="proposalContainer">
            <div className="proposalBodyContainer">
                <h1>{title}</h1>
                <pre>{body}</pre>
            </div>
            <div>
                {isLoading ? <LoadingSpinner /> : renderAnswers}
                {errorMessage && <div className="error">{errorMessage}</div>}
            </div>
        </div>
    )
}