import "./TraitSwap.css";
import React, { useState, useEffect, useRef } from 'react'; 
import { useDetectOutsideClick } from "../../Utils/ClickHandling/useDetectOutsideClick";


export const TraitSwapDropdown = ({props, returnSelectedLayer, returnSelectedValue}) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);

  const [selectedTrait, setSelectedTrait] = useState("Trait");
  const [selectedLayer, setSelectedLayer] = useState("Select");
  
  const [showTrait5, setShowTrait5] = useState("none");
  const [showTrait6, setShowTrait6] = useState("none");
  const [showTrait7, setShowTrait7] = useState("none");
  const [showTrait8, setShowTrait8] = useState("none");

  const [layer1, setLayer1] = useState()
  const [layer2, setLayer2] = useState()
  const [layer3, setLayer3] = useState()
  const [layer4, setLayer4] = useState()
  const [layer5, setLayer5] = useState()
  const [layer6, setLayer6] = useState()
  const [layer7, setLayer7] = useState()
  const [layer8, setLayer8] = useState()

  const [value1, setValue1] = useState()
  const [value2, setValue2] = useState()
  const [value3, setValue3] = useState()
  const [value4, setValue4] = useState()
  const [value5, setValue5] = useState()
  const [value6, setValue6] = useState()
  const [value7, setValue7] = useState()
  const [value8, setValue8] = useState()

  useEffect(async () => {
    if(props.length > 0){
        setLayer1(props[0].traitType)
        setLayer2(props[1].traitType)
        setLayer3(props[2].traitType)
        setLayer4(props[3].traitType)
        //setLayer5(props[4].traitType)
        setValue1(props[0].value)
        setValue2(props[1].value)
        setValue3(props[2].value)
        setValue4(props[3].value)
        //setValue5(props[4].value)

        //console.log(props)

        if(props.length > 4){
          setLayer5(props[4].traitType)
          setValue5(props[4].value)  
          setShowTrait5("unset")              
        }

        if(props.length > 5){
            setLayer6(props[5].traitType)
            setValue6(props[5].value)  
            setShowTrait6("unset")              
        }

        if(props.length > 6){
            setLayer7(props[6].traitType)
            setValue7(props[6].value) 
            setShowTrait6("unset")               
            setShowTrait7("unset")                            
        }

        if(props.length > 7){
          setLayer8(props[7].traitType)
          setValue8(props[7].value) 
          setShowTrait6("unset")               
          setShowTrait7("unset")   
          setShowTrait8("unset")                        
        }

        if(props.length < 6){
            setShowTrait6("none")              
            setShowTrait7("none")  
            setLayer6("")
            setValue6("")             
        }

        if(props.length < 7){
           //setShowTrait6("none")              
            setShowTrait7("none")  
            //setLayer6("")
            //setValue6("")  
            setLayer7("")
            setValue7("")              
        }

        if(props.length < 8){
          setShowTrait8("none")   
          setLayer8("")
          setValue8("")              
      }
    }
});

useEffect(async () => {
  returnSelectedLayer(selectedLayer)
  returnSelectedValue(selectedTrait)
});

const SelectedTrait = (layer, value) =>{
    setSelectedLayer(layer)
    setSelectedTrait(value)
    setIsActive(false)

}

  return (
      <div className="dropDownMenu-container">
        <button onClick={onClick} className="dropDownMenu-trigger">
          <span>{selectedLayer} : {selectedTrait}</span>
        </button>
        <div
          ref={dropdownRef}
          className={`dropDownMenu ${isActive ? "active" : "inactive"}`}
        >
          <ul>
            <li onClick={() => SelectedTrait(layer1, value1)}>
              <a>{layer1} : {value1}</a>
            </li>
            <li onClick={() => SelectedTrait(layer2, value2)}>
              <a>{layer2} : {value2}</a>
            </li>
            <li onClick={() => SelectedTrait(layer3, value3)}>
              <a>{layer3} : {value3}</a>
            </li>
            <li onClick={() => SelectedTrait(layer4, value4)}>
              <a>{layer4} : {value4}</a>
            </li>
            <li onClick={() => SelectedTrait(layer5, value5)} style={{disply:showTrait5}}>
              <a>{layer5} : {value5}</a>
            </li>
            <li onClick={() => SelectedTrait(layer6, value6)} style={{disply:showTrait6}}>
              <a>{layer6} : {value6}</a>
            </li>
            <li onClick={() => SelectedTrait(layer7, value7)} style={{disply:showTrait7}}>
              <a>{layer7} : {value7}</a>
            </li>
            <li onClick={() => SelectedTrait(layer8, value8)} style={{disply:showTrait8}}>
              <a>{layer8} : {value8}</a>
            </li>
          </ul>
        </div>
      </div>
  );
}
