import React, { useEffect, useState } from 'react'
import { Card, Row, Col, Container } from "react-bootstrap";
import { getCurrentWalletConnected, walletConnectWallet, clearWalletConnectWallet } from "./../Wallet/Interact";
import "./Card.css"
const { toChecksumAddress } = require('ethereum-checksum-address')

const CardTraits = ({handler, handler2, props}) => {
    const [repos, setRepos] = useState([]);
    const imagesArray = []
    const [isLoading, setLoading] = useState(true);
    const clours = ["white", "red", "red"];


    const handleClick = (value, traitType, index) => {
        setSelectedImage(
            selectedImage.map((image, i) =>
                // image.value === value
                //     ? { ...image, Colour: "#FF33EE"}
                //     : { ...image, Colour: ""}
                i === index
                    ? { ...image, Colour: "#FF33EE"}
                    : { ...image, Colour: ""}
            )
        )
        handler(value)
        handler2(traitType)
    }

    const [selectedImage, setSelectedImage] = useState(props);

    return (
        <Container>
            <Row>
                {selectedImage.map((selectedImage, k) => (
                    <Col key={k} xs={12} md={4} lg={3}>
                        <Card>
                            <Card.Img 
                                src={selectedImage.imageUrl} 
                                onClick={() => handleClick(selectedImage.value, selectedImage.traitType, k)}
                                style={{borderColor: selectedImage.Colour}}
                                />
                            <Card.Body>
                                <Card.Text>{selectedImage.value}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    )
}

export default CardTraits;
