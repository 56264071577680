import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { MenuList, menuList2 } from "./MenuList";
import "./Navbar.css";
//import "../../Minting/Minter"
// import Minter from "../../minter/Minter";
// import Logo from './../../images/LogoMainNoBk.png'

const Navbar = () => {
  const [clicked, setClicked] = useState(false);
  const [style, setStyle] = useState("")

  const menuList = MenuList.map(({ url, title }, index) => {

    return (
      <li key={index}>
        <NavLink exact to={url} activeClassName="active"> 
          {/* onClick={changeStyle}> */}
          {title}
        </NavLink>
      </li>
    );
  });

  const walletButton = () => {
    return(
      <a>
      {/* <Minter/> */}
      </a>
    );
  }

  const handleClick = () => {
    setClicked(!clicked);
  };


  return (
    <div>
      <nav className="nav">        
        <div className="menu-icon" onClick={handleClick}>
          <i className={clicked ? "fa fa-times" : "fa fa-bars"}></i>
        </div>
        <ul>
          <ul className={clicked ? "menu-list" : "menu-list close"}>{menuList}</ul>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
