import React, { useState, useEffect, useRef } from 'react'; 
import "./LoginScreen.css"
import WalletConnector from "./../../Utils/Wallet/WalletConnector"

const LoginScreen = () => {

    return(
        <div className='loginScreen'>
              <WalletConnector></WalletConnector>
        </div>
    )
}

export default LoginScreen;