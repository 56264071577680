import {RequestsArray} from "./RequestArray"
import "./../TraitSwap.css"
import React, { useState, useEffect, useRef } from 'react'; 
import { getCurrentWalletConnected, walletConnectWallet, clearWalletConnectWallet } from "./../../../Utils/Wallet/Interact";
import LoadingSpinner from "./../../../Utils/LoadingSpinner/LoadingSpinner";

export const RequestsContainer = () => {
    const [requestArray, setRequestArray] = useState([])
    const [showRequests, setShowRequets] = useState("none")
    const [noRequests, setNoRequets] = useState("none")
    const { toChecksumAddress } = require('ethereum-checksum-address')
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(async () => {
        setIsLoading(true);
        var wallet2 = await (await getCurrentWalletConnected()).address
        try{
            //fetch("https://nudies.s3.us-west-1.amazonaws.com/Requests/" + toChecksumAddress(wallet2) + ".json")
            fetch("https://nudies.s3.us-west-1.amazonaws.com/Requests/" + toChecksumAddress(wallet2) + ".json")
            .then((response) => response.json())
            .then((data) => setRequests(data))
            .catch((err) => {
                setIsLoading(false);
                setNoRequets("block")
                console.log(err.message);
            });
            return () => {
            };
        }catch(err){
            console.log(err)
        }
    }, []);

    async function setRequests(data){
        let requests = []

        for (let i = 0; i < data.length; i++) {
            requests.push(JSON.parse(data[i])) 
            setShowRequets("block")   
        }

        if(data != null){
            if(data.length === 0){
                setNoRequets("block")
                setShowRequets("none")   
            }
        }
        setIsLoading(false);
        setRequestArray(requests)        
    }

    const renderRequests = (
        <div>
            <div style={{display:showRequests}}>
                <RequestsArray requests={requestArray}></RequestsArray>
            </div>
            <div style={{display:noRequests}}>
                <h2>No Requests</h2>
            </div>
        </div>
    )

    return(
        <div className="requestHolder">
                {isLoading ? <LoadingSpinner/> : renderRequests}
                {errorMessage && <div className="error">{errorMessage}</div>}
            {/* <RequestsArray requests={requestArray}></RequestsArray> */}
        </div>
    )
}