import {ethers, BigNumber, providers} from "ethers";
import Web3 from "web3"
import React, {useState, useEffect } from "react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useWeb3React } from '@web3-react/core'
// import { InjectedConnector } from "@web3-react/injected-connector";
// import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";

const provider = new WalletConnectProvider({
  infuraId: "1069aa1f3bb848b98352b71ad7752caf",
});

var WalletConnectToggle = false

export const walletConnectWallet = async () => {
  try {
    await provider.enable();
    const obj = {
      address: provider.accounts[0],
    };
    WalletConnectToggle = true
    return obj      
  } catch (error) {
    console.log(error)
  }
};



export const clearWalletConnectWallet = async () => {
  if(WalletConnectToggle = true){
    await provider.disconnect();
    WalletConnectToggle = false
  }
}

export const connectWallet = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  //console.log(provider)
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: "",
      };
    }
  } else {
    return {
      address: "",
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
        };
      } else {
        return {
          address: "",
        };
      }
    } catch (err) {
      return {
        address: "",
      };
    }
  } else {
    return {
      address: ""
    };
  }
};

const InfuraKey = "1069aa1f3bb848b98352b71ad7752caf"

const CoinbaseWallet = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/$1069aa1f3bb848b98352b71ad7752caf`,
  appName: "NudieStaking",
  supportedChainIds: [1, 3, 4, 5, 42],
 });



export const coinbaseWalletConnect = async () => {

  try {
    //await CoinbaseWallet.request();
    //activate(CoinbaseWallet)
    const obj = {
      address: CoinbaseWallet.accounts[0],
    };
    return obj
  } catch (error) {
    console.log(error)
  }
};
// const injected = new InjectedConnector({
//   supportedChainIds: [1, 3, 4, 5, 42]
// });

// const walletconnect = new WalletConnectConnector({
//   rpcUrl: `https://mainnet.infura.io/v3/${process.env.INFURA_KEY}`,
//   bridge: "https://bridge.walletconnect.org",
//   qrcode: true
// });

// const walletlink = new WalletLinkConnector({
//   url: `https://mainnet.infura.io/v3/${process.env.INFURA_KEY}`,
//   appName: "web3-react-demo"
// });

// export const connectors = {
//   injected: injected,
//   walletConnect: walletconnect,
//   coinbaseWallet: walletlink
// };