import "./Card.css"
import React, { useEffect, useState } from 'react'
import { Card, Row, Col, Container } from "react-bootstrap";

const CardsStaked = ({handler, props}) => {

    const handleClick = (ID) => {
        setSelectedImage(
            selectedImage.map((image) =>
                image.ID === ID
                    ? { ...image, Colour: "#FF33EE"}
                    : { ...image, Colour: ""}
            )
        )
        handler(ID)
    }

    const [selectedImage, setSelectedImage] = useState(props);

    useEffect(() => {
        setSelectedImage(props)
    })

    return (
        <Container>
            <Row>
                {selectedImage.map((selectedImage, k) => (
                    <Col key={k} xs={12} md={4} lg={3}>
                        <Card>
                            <Card.Img 
                                src={selectedImage.Image} 
                                onClick={() => handleClick(selectedImage.ID)}
                                style={{borderColor: selectedImage.Colour}}
                                />
                            <Card.Body>
                                <Card.Text>{selectedImage.ID}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    )
}

export default CardsStaked