import "./Shop.css"
import SignMessageInput from "../../Utils/Blockchain/SignMessage"
import {SendShopAPIPost} from "../../Utils/APIs/BackEndAPIs"
import { getCurrentWalletConnected} from "./../../Utils/Wallet/Interact";
import Snackbar from "./../../Utils/Snackbar/Snackbar";
import React, { useState, useEffect, useRef } from 'react'; 
import { getConnectedERC20Contract } from '../../Utils/Contracts/ContractConnections';
import { ethers, wait } from "ethers";
import {CheckItemQuantity} from "./CheckItemQuantity"

const SnackbarType = {
    success: "success",
    fail: "fail",
};

export const ItemSection = ({ID, itemSKU, trait_type, value, itemName, price, imageUrl, quantity, setShopIsLoading}) =>{

    const snackbarRef = useRef(null);
    const [snackBarType, SetSnackBarType] = useState(SnackbarType.fail)
    const [snackBarMessage, SetSnackBarMessage] = useState("")
    // const [skinsDisplay, SetSkinsDisplay] = useState(false)

    // async function setChosenItem(){
    //     //setChosenItem(itemName)
    //     console.log(ID, itemSKU, trait_type, value, itemName, price, imageUrl)
    // }

    async function setChosenItem(){
        var wallet = await (await getCurrentWalletConnected()).address
        //var succesfulTransaction = await sendEthForPurchase()

        const unixTime = Math.floor(Date.now() / 1000);

        const JSONString = JSON.stringify({ 
            function: "BuyShopItem",
            wallet: wallet,
            trait_type : trait_type,
            value : value,
            itemName : itemName,
            price : price,
            quantity: quantity,
            purchaseTime: unixTime})

        //if(succesfulTransaction){
            const singedResponse = await SignMessageInput(JSONString)
            const responseStatus = await SendShopAPIPost(singedResponse)

        
            if(singedResponse.signature.length < 1){

                SetSnackBarMessage("Singnature Declined")
                SetSnackBarType(SnackbarType.fail)
                snackbarRef.current.show()       

            }else{    
                if(responseStatus == 200){
                    SetSnackBarMessage("Request Sent")
                    SetSnackBarType(SnackbarType.success)
                    snackbarRef.current.show()  
                }
                if(responseStatus == 500){
                    //const secondResponseStatus = await SendShopAPIPost(singedResponse) 
                    SetSnackBarMessage("Request Failed Please Try Signature Again")
                    SetSnackBarType(SnackbarType.fail)
                    snackbarRef.current.show()  
                    setChosenItem()
                    // if(secondResponseStatus == 500){    

                        
                    // }
                    // if(secondResponseStatus == 200){
                    //     SetSnackBarMessage("Request Sent")
                    //     SetSnackBarType(SnackbarType.success)
                    //     snackbarRef.current.show()  
                    // }
                }  
            }  
            
            //console.log(singedResponse)
        //}
    }

    async function sendEthForPurchase(){
        var tokensToSend = price.toString() + "000000000000000000"
        //setChosenItem()

        if(quantity > 0){

            let SecondQuantityCheck = await CheckItemQuantity(value)

            if(SecondQuantityCheck){
                try{ 
                    const connectedERC20Contract = await getConnectedERC20Contract()
                    var wallet = await (await getCurrentWalletConnected()).address
                    var treasuryWallet = "0x086E9d3A1Ce350a16C843AbAa184B65F810A790c"

                    if(!connectedERC20Contract) return;
                        try {
                            setShopIsLoading(true)
                            var transactionID = ""
                            var transactionIDMatch = ""

                            const transaction = await connectedERC20Contract.transfer("0x086E9d3A1Ce350a16C843AbAa184B65F810A790c", tokensToSend)
                                .then(function(tx) {
                                    //console.log(tx); 
                                    transactionID = tx
                                    transactionIDMatch = tx

                                });
                            connectedERC20Contract.on("Transfer", (treasuryWallet, tokensToSend, wallet, transactionID) => {
                                //console.log(treasuryWallet, tokensToSend, wallet, transactionID.transactionHash);

                                if(transactionIDMatch.hash == transactionID.transactionHash){
                                    //console.log("worked")
                                    setChosenItem()
                                    setShopIsLoading(false)
                                }
                            });
                        } catch (error) {
                            console.log(error);
                            setShopIsLoading(false)
                        }
                    } 
                catch (err){
                    console.log(err);
                    setShopIsLoading(false)
                }                
            }else{
                SetSnackBarMessage("No Items Left")
                SetSnackBarType(SnackbarType.fail)
                snackbarRef.current.show() 
            }
        }else{
            SetSnackBarMessage("No Items Left")
            SetSnackBarType(SnackbarType.fail)
            snackbarRef.current.show() 
        }

        return false
    }


    return(
        <div className="">
            <Snackbar
                ref={snackbarRef}
                message={snackBarMessage}
                type={snackBarType}
            />
            <div className="itemCardContainer">
                <h1>{itemName}</h1>
                <div className="shopItemImageSection">
                    <div className="shopItemCard">
                        <div className="shopItemCardInnerBorder">
                            <div className="shopItemCardInner">
                                <img src={imageUrl}/>
                            </div>
                            {/* <div className="shopItemCardInner" style={{display:}}>
                                <img src={imageUrl}/>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="shopItemCardBottomSection">
                    <div className="itemQuantity">{quantity} Remaining</div>
                </div>
                <div className="shopItemCardBottomSection">
                    <div className="itemPrice">{price}</div>
                    <button className="itemBuybutton" onClick={sendEthForPurchase}>BUY</button>
                </div>
            </div>
        </div>
    )
}