import {Proposals} from "./Proposals"
import "./Voting.css"

export const ProposalContainer = ({proposalData, votingPower}) => {
    return(
        <div className="proposalRow">
            {proposalData.slice(0).reverse().map(function(items, index){
                return (<Proposals 
                            key={index}
                            ID={items.ID}
                            title={items.title}
                            body={items.body}
                            startTime={items.trait_type}
                            endTime={items.endTime}
                            answers={items.answers}
                            votePower={votingPower}
                        />)
                })}
        </div>
    )
}