import { useEffect, useState, useRef } from "react";
import { BrowserRouter as Router, Switch, Route, useLocation, Redirect} from "react-router-dom";
import React, { Component } from 'react';
import { connectWallet, getCurrentWalletConnected, walletConnectWallet, coinbaseWalletConnect, clearWalletConnectWallet } from "./Interact.js";
import "./WalletConnector.css"
import fox from "../../Images/MetaMaskFox.png"
import walleConnect from "../../Images/walleConnect.png"
import coinbase from "../../Images/coinbase.png"
import Staking from "../../Components/Staking/Staking";
import { providers } from "ethers";


import Snackbar from "./../Snackbar/Snackbar"
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const WalletConnector = (props) => {
  
  const SnackbarType = {
    success: "success",
    fail: "fail",
  };

  const snackbarRef = useRef(null);
  const [snackBarType, SetSnackBarType] = useState(SnackbarType.fail)
  const [snackBarMessage, SetSnackBarMessage] = useState("")
  const [walletAddress, setWallet] = useState("");
  
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
        } else {
          setWallet("");
        }
      });
    } else {
    }
  }


  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
    setTimeout(() => {
      if(walletResponse.address.length > 1){
        setLoggedIn(true);
        try {
          SetSnackBarMessage("Wallet Connected")
          SetSnackBarType(SnackbarType.fail)
          snackbarRef.current.show()            
        } catch (error) { 
        }
      }else{
        try {
          SetSnackBarMessage("No Wallet Detected")
          SetSnackBarType(SnackbarType.fail)
          snackbarRef.current.show()          
        } catch (error) {     
        }
      }
      }, 500);
  };

  const WalletConnectPressed = async () => {
    try {
      const walletResponse = await walletConnectWallet();
      setWallet(walletResponse.address);
      //console.log(walletResponse.address)
      
      setTimeout(() => {
        if(walletResponse.address.length > 1){
          setLoggedIn(true);
          try {
            SetSnackBarMessage("Wallet Connected")
            SetSnackBarType(SnackbarType.fail)
            snackbarRef.current.show()            
          } catch (error) { 
          }
        }else{
          try {
            SetSnackBarMessage("No Wallet Detected")
            SetSnackBarType(SnackbarType.fail)
            snackbarRef.current.show()          
          } catch (error) {     
          }
        }
        }, 500);

    } catch (error) { 
      SetSnackBarMessage("Error with WalletConnect")
      SetSnackBarType(SnackbarType.fail)
      snackbarRef.current.show()  
    }
  };


  const CoinbaseWalletPressed = async () => {
    try {
      const walletResponse = await coinbaseWalletConnect();
      setStatus(walletResponse.status);
      setWallet(walletResponse.address);      
    } catch (error) { 
    }
  };

  return (
    <div  data-aos="zoom-in" >
      <Snackbar
        ref={snackbarRef}
        message={snackBarMessage}
        type={snackBarType}
      />
      <div className="WalletConnector">
          <h1>Please Connect Your Wallet</h1>
          <button className="walletButton" onClick={connectWalletPressed}>
              <img src={fox}/>
          </button>
          <Route exact path="/" render={() => (
              loggedIn ? (
                <Redirect to="/Staking"/>
              ) : (
                <Redirect to="/"/>
              )
            )}/>
          <button className="walletButtonDisabled" onClick={WalletConnectPressed} disabled={true}>
              <img src={walleConnect}/>
              <Route exact path="/" render={() => (
              loggedIn ? (
                <Redirect to="/Staking"/>
              ) : (
                <Redirect to="/"/>
              )
            )}/>
          </button>
          <button className="walletButton" onClick={connectWalletPressed}>
              <img src={coinbase}/>
          </button>
      </div>    
    </div>
  );
};

export default WalletConnector;