import React, { useState, useEffect, useRef } from 'react'; 
import {filter, utils} from "ethers";
import CardTraits from "../../Utils/Cards/CardTraits"
import Card from '../../Utils/Cards/Card';
import { Button } from 'react-bootstrap'
import "./Inventory.css"
import { getCurrentWalletConnected, walletConnectWallet, clearWalletConnectWallet } from "./../../Utils/Wallet/Interact";
import { fetchOwnCollection, loadStakedNudies } from '../../Utils/APIs/BlockChainAPIs';
import {Redirect, Route} from "react-router-dom";
import { getConnectedStakingContract, getConnectedERC721Contract, getConnectedERC20Contract } from '../../Utils/Contracts/ContractConnections';
//import coin from "./../../Images/coin.png"
import coin from "./../../Images/cashnipcoin.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Snackbar from "./../../Utils/Snackbar/Snackbar";
import {GetTraitsOfJson} from "../TraitIndexes/TraitIndexes"
import { InventoryRemover } from "./InventoryRemover";
import { ClosetInitialization } from "./ClosetInitialization";
import SignMessageInput from "../../Utils/Blockchain/SignMessage"
import logo from "../../Images/nudieLogo.png"
import {SendAPIPost} from "../../Utils/APIs/BackEndAPIs"
import allowedWallets from "./../allowedWallets.json"

require('dotenv').config()
AOS.init();

const APIKEY1 = process.env.REACT_APP_KEY1;

const SnackbarType = {
    success: "success",
    fail: "fail",
};


const Inventory = () => { 
    const snackbarRef = useRef(null);
    const [snackBarType, SetSnackBarType] = useState(SnackbarType.fail)
    const [snackBarMessage, SetSnackBarMessage] = useState("")

    const [chosenNudie, setChosenNudie] = useState("0")
    const [chosenTrait, setChosenTrait] = useState()
    const [chosenTraitType, setChosenTraitType] = useState()
    const [showChosenNudie, setShowChosenNudie] = useState("none")
    const [closetInitializationActive, setClosetInitializationActive] = useState("none")

    const [nudieTraits, setNudieTraits] = useState([])
    const [displayBox, setDisplayBox] = useState("none")
    const [displayInventoryBox, setDisplayInventoryBox] = useState("none")

    const [chosenStakedImage, setChosenStakedImage] = useState([])
    const [chosenImage, setChosenImage] = useState([])
    const [chosenTraitImage, setChosenTraitImage] = useState([])
    const [chosenNudieImage, setChosenNudieImage] = useState("")

    const [walletAmount, setWalletAmount] = useState(0)
    const [tokensToClaim, setTokensToClaim] = useState(0)

    const [wallet, setWallet] = useState()//getCurrentWalletConnected());

    const [displayWallet, setDisplayWallet] = useState("");
    const [logBackIn, setLogBackIn] = useState(false);
    const [showInfo, setShowInfo] = useState("none");

    const [showInventoryBox, setShowInventoryBox] = useState("none");
    const [showAuth, setShowAuth] = useState("none");
    const [showUnstakeInfo, setUnstakeInfo] = useState("none");

    const [openseaAPIFailure, setOpenseaAPIFaliure] = useState(false)

    const [hideAll, setHideAll] = useState("none")

    var imagesArray = []
    var traitImagesArray = []
    var nudieTradedArray = []
    var wallet2 = ""
    var closetInitializationNeeded = "block";
    //var closetInitializationActive = "none";
    var traits = []

    const { toChecksumAddress } = require('ethereum-checksum-address')
  
    // useEffect(async () => {
    //     let wallet = await (await getCurrentWalletConnected()).address
    //     let matched = false

    //     for (let i = 0; i < allowedWallets.length; i++) {
    //         if(toChecksumAddress(wallet) == allowedWallets[i].walletID){
    //             matched = true
    //             setHideAll("block")
    //         }
    //     }
    // }, []);

    useEffect(async () => {
        // if(chosenNudie >= 1){
        //     setShowChosenNudie("block")
        //     setChosenNudieImage("https://nudiecommunity.mypinata.cloud/ipfs/QmXAXsvjKpVE8Ar2EhCSm5MvFMAWe6pnXNzkAra3ekDsnD/" + chosenNudie + ".png")
        // }
        if(chosenNudie > 0){
            setShowChosenNudie("block")
            traits = await GetTraitsOfJson(chosenNudie)
            SetImages()
        }
        if(chosenNudie == 0){
            setShowChosenNudie("block")
            setChosenNudieImage(logo)
        }
        return () => {
            setShowChosenNudie("block")
            setChosenNudieImage("https://nudies.s3.us-west-1.amazonaws.com/Assets/img/" + chosenNudie + ".png")
            setNudieTraits(traits)
        };
        ////console.log(traits)
    });

    const SetImages = async () => {
        setShowChosenNudie("block")
        setChosenNudieImage("https://nudies.s3.us-west-1.amazonaws.com/Assets/img/" + chosenNudie + ".png")
        setNudieTraits(traits)
    }

    useEffect(async () => {
        wallet2 = await (await getCurrentWalletConnected()).address
        //if(!wallet2){wallet2 = await (await walletConnectWallet()).address}
        setDisplayWallet(wallet2)
        //console.log(wallet2)
        try{
            fetchOwnCollection()
            .then((response) => RenderNudies(response))
            .catch((err) => {
                //console.log(err.message);
                setOpenseaAPIFaliure(true)
                // SetSnackBarMessage("Opensea API request timeout, please reload page. Fallback API loaded")
                // SetSnackBarType(SnackbarType.fail)
                // snackbarRef.current.show()  
                fetch("https://api.etherscan.io/api?module=account&action=tokennfttx&contractaddress=0x89Bd11D1fBa7906464fa11f3086fE8ba1B5368A1&address=" + wallet2 + "&offset=100&startblock=0&endblock=27025780&sort=asc&apikey=T7U7YX32H4KYIBBJ1NT6BVJWZHWQQ7NY4B", {cache: "no-store"})
                .then((response) => response.json())
                .then((actualData) => loadUserNudies(actualData))
                .catch((err) => {
                    //console.log(err.message);
                });
            });
            loadStakedNudies()
            return () => {
            };
        }catch(err){
            //console.log(err)
        }
    }, []);

    async function RenderNudies(response){
        if(response.length != null){
            for(var i = 0; i < response.length; i++){
                imagesArray.push(
                    {
                        "ID": response[i],
                        "Image": "https://nudies.s3.us-west-1.amazonaws.com/Assets/img/" + response[i] + ".png",
                        "Colour": "",                        
                    }
                )         
            }      
            setChosenImage(imagesArray)            
        }
        //console.log(imagesArray)
    }

    async function loadUserNudies(actualData){
        let match = false;
        let matchFromStaker = false;
        const loadWallet = wallet2;
        var nudieMatchArray = []
        try{
            for (let j = 0; j < actualData.result.length; j++) {
                if(actualData.result[j].from == loadWallet || toChecksumAddress(actualData.result[j].from.toString()) == loadWallet){
                    for (let o = j; o < actualData.result.length; o++) {

                        if(actualData.result[o].from == "0x8dd465df3d1d4e0a4ae978fa7fb30eb558ab8714" || actualData.result[o].to == "0x8dd465df3d1d4e0a4ae978fa7fb30eb558ab8714"){
                            if(actualData.result[o].tokenID == actualData.result[j].tokenID){
                                matchFromStaker = true;                                
                            }
                        }

                        if(actualData.result[o].to == loadWallet || toChecksumAddress(actualData.result[o].to.toString()) == loadWallet){
                            if(actualData.result[o].tokenID == actualData.result[j].tokenID){
                                matchFromStaker = true;                                
                            }                        
                        }
                    }
                    if(matchFromStaker == false){
                        nudieTradedArray.push(actualData.result[j].tokenID)
                    }
                    matchFromStaker = false
                } 
            }   
            for(var i = 0; i < actualData.result.length; i++){
                match = false;
                    for (let l = 0; l < nudieMatchArray.length; l++) {
                        if(nudieMatchArray[l] == actualData.result[i].tokenID) {
                            match = true;
                        }  
                    }                  
                    if(match == false){

                        if (nudieTradedArray.indexOf(actualData.result[i].tokenID) == -1)
                            {
                                imagesArray.push(
                                    {
                                        "ID": actualData.result[i].tokenID,
                                        "Image": "https://nudies.s3.us-west-1.amazonaws.com/Assets/img/" + actualData.result[i].tokenID + ".png",
                                        "Colour": "",                        
                                    }
                                )  
                            }
                    }
                    nudieMatchArray.push(actualData.result[i].tokenID)
            }
        }catch{
            //console.log("Images Cant Load")
        }
        setChosenImage(imagesArray)
    }

    async function swapTrait(){
        console.log("calling swpa trait")
        var wallet = await (await getCurrentWalletConnected()).address

        if(chosenNudie > 0 && chosenTrait != null){

            const JSONString = JSON.stringify({
                Function: "InventorySwap", 
                NudieNFTNumber: chosenNudie,
                Wallet: wallet,
                InventoryItemSwap: chosenTrait,
                InventoryItemSwapType: chosenTraitType
            })

            const singedResponse = await SignMessageInput(JSONString)

            if(singedResponse.signature.length < 1){

                SetSnackBarMessage("Singnature Declined")
                SetSnackBarType(SnackbarType.fail)
                snackbarRef.current.show()       

            }else{
                const responseStatus = await SendAPIPost(singedResponse) 

                if(responseStatus == 200){
                    SetSnackBarMessage("Request Sent")
                    SetSnackBarType(SnackbarType.success)
                    snackbarRef.current.show()  
                }
                
                if(responseStatus == 500){
                    const secondResponseStatus = await SendAPIPost(singedResponse)  

                    if(secondResponseStatus == 500){    
                        SetSnackBarMessage("Request Failed Please Try Again")
                        SetSnackBarType(SnackbarType.fail)
                        snackbarRef.current.show()  
                    }
                    if(secondResponseStatus == 200){
                        SetSnackBarMessage("Request Sent")
                        SetSnackBarType(SnackbarType.success)
                        snackbarRef.current.show()  
                    }
                }  
            }
        }            
        //console.log(chosenTrait)
    }

    async function CheckItemStillExists(){
        var wallet = await (await getCurrentWalletConnected()).address
        var closetUrl = "https://nudies.s3.us-west-1.amazonaws.com/Closet/" + toChecksumAddress(wallet) + ".json"
        let itemFound = false

        fetch(closetUrl, {cache: "no-store"})
        .then((response) => response.json())
        .then((data) => checkForItem(data))
        .catch((err) => {
            console.log(err)
        });

        // if(itemFound){
        //     swapTrait()
        // }else{
        //     SetSnackBarMessage("Item No Longer In Inventory")
        //     SetSnackBarType(SnackbarType.fail)
        //     snackbarRef.current.show() 
        // }
    }

    const checkForItem = async (data) => {
        let matchFound = false
        if(data.attributes.length != null){
            for(var i = 1; i < data.attributes.length; i++){                  
                if(data.attributes[i].value == chosenTrait){
                    //console.log("match found")
                    swapTrait()
                    matchFound = true
                    break
                }  
            }      
        }
        if(matchFound == false){
            SetSnackBarMessage("Item No Longer In Inventory")
            SetSnackBarType(SnackbarType.fail)
            snackbarRef.current.show() 
        }
    }

    async function removeTrait(){
        if(chosenNudie >= 1){
            //traits = await GetTraitsOfJson(chosenNudie)
            //TraitList(traits)
            ////console.log(traits)
            ////console.log(traits)
            setDisplayBox("block")
            ////console.log(nudieTraits)
            showRemoveInventoryBox()            
        }
    }

    function showRemoveInventoryBox(){
        setShowInventoryBox("block")
    }
    function hideRemoveInventoryBox(){
        setShowInventoryBox("none")
    }
    function hideUnstakeInfoBox(){
        setUnstakeInfo("none")
    }

    useEffect(async () => {
        RetrieveClosetItems();
    }, [])

    const RetrieveClosetItems = async () => {
        var wallet2 =  await (await getCurrentWalletConnected()).address

        var closetUrl = "https://nudies.s3.us-west-1.amazonaws.com/Closet/" + toChecksumAddress(wallet2) + ".json"
        console.log(closetUrl)
        try {
            fetch(closetUrl, {cache: "no-store"})
            .then((response) => response.json())
            .then((actualData) => loadUserClosetItems(actualData))
            .catch((err) => {
                //console.log(err);
                renderClosetInitButton();
            });

        } catch (err) {
            //console.log(err)
        }
    }

    const loadUserClosetItems = (actualData) =>{
        console.log(actualData)
        //console.log(actualData.attributes)
        if(actualData.attributes.length != null){
            for(var i = 1; i < actualData.attributes.length; i++){
                traitImagesArray.push(
                    {
                        "traitType": actualData.attributes[i].trait_type,
                        "value": actualData.attributes[i].value,
                        "imageUrl": actualData.attributes[i].image_url,                        
                    }
                )         
            }      
            setChosenTraitImage(traitImagesArray)            
        }
        //console.log(traitImagesArray)
        ////console.log(actualData)
    }

    async function renderClosetInitButton(){
        setClosetInitializationActive("block")
        closetInitializationNeeded = "none";
        //closetInitializationActive = "block";        
    }

    async function setUpCloset(){
        setDisplayInventoryBox("block")
    }

    async function LogBackIn(){
        setLogBackIn(true)
        wallet2 = null;
        //await clearWalletConnectWallet();
    }

    function hideInfoBox(){
        setShowInfo("none")
    }

    function showInfoBox(){
        setShowInfo("block")
    }


    return(
        // <div className='comingSoon'>
        //     <h1>COMING SOON</h1>    
        // </div>
        <div className='inventory'>
                <Snackbar
                    ref={snackbarRef}
                    message={snackBarMessage}
                    type={snackBarType}
                />
            {/* <div className='infoPannelBK' style={{
                    display:showInventoryBox
                }}>  */}
                <ClosetInitialization prop2={displayInventoryBox} handler={setDisplayInventoryBox}/>
                <InventoryRemover prop2={displayBox}  props={nudieTraits} handler={setDisplayBox} chosenNudie={chosenNudie}/>     
                <div className='infoPannelBK' style={{
                    display:showInfo
                }}>      
                <div className='infoPannel'>
                    <div className='infoPannelHeader'>
                        <h1>Inventory Information</h1>
                        <button onClick={hideInfoBox} className='closeInfo fa fa-times'>
                        </button>
                    </div>
                    <div className='infoPannelFooter'>
                        <div className='stakeInfo'>
                            <p>To swap a trait, simply select the Nudie below, select an item from your closet, and press
                                the 'Swap Trait' button.</p>
                            <p>To remove a trait, select your Nudie from below and click 'Remove Trait'.</p>
                            <p>Only certain traits can be removed to avoid breaking the NFT.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div data-aos="fade-left" className='topContainer'>
                <div className='infoContainer'>
                    <Button className='walletConnected' onClick={LogBackIn}>
                        {displayWallet.length > 0 ? (
                        String(displayWallet).substring(0, 4) +
                        "..." +
                        String(displayWallet).substring(39)
                        ) : (
                        <span>Wallet Error</span>
                        )}
                    <Route exact path="/Staking" render={() => (
                        logBackIn ? (
                            <Redirect to="/"/>
                        ) : (
                            <Redirect to="/Staking"/>
                        )
                        )}/>
                    </Button>
                    <Button className='walletConnected' onClick={showInfoBox}>
                        Inventory Info
                    </Button>
                </div>
            </div>
            <div className='stakingContainer'>
                <div data-aos="fade-right" className='leftContainer'>
                    <div className='InventoryContianer'>
                        <h1>Selected Owned Nudie</h1>
                        <div className='chosenNudieHolder'>
                            <img
                                style={{display : showChosenNudie}} 
                                src={chosenNudieImage}/>
                        </div>
                        <h1>#{chosenNudie}</h1>
                    </div>
                </div>
                <div className='inventorySelectNudieContianerMobile'>
                    <div className='selectNudieContianerMobile'>
                        <h1>Select Nudie</h1>
                        <div className='selectNudieHolder'>
                            <Card handler={setChosenNudie} props={imagesArray}/>
                        </div>
                    </div>                    
                </div>

                <div className='middleContainer'>
                    <div className='inventoryControls'>
                        <Button className='stakeButton' onClick={CheckItemStillExists}>Swap Trait</Button>
                        <div className='escrowInventoryContainer'>

                        </div>
                        <Button className='unstakeButton' onClick={removeTrait}>Remove Trait</Button>
                    </div>
                </div>
                <div className='rightContainer'>
                    <div className='ClosetContianer'>
                        <h1>Closet</h1>
                        <div className='stakedNudieHolder'>
                            <CardTraits handler={setChosenTrait} handler2={setChosenTraitType} props={traitImagesArray} style={{display : closetInitializationNeeded}}/>
                            <div className='inventoryInitContainer'>
                                <Button className='initClosetTraitButton' style={{display : closetInitializationActive}} onClick={setUpCloset}>Initialize Closet</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bottomInventoryContainer'>
                <div className='selectNudieContianer'>
                    <h1>Select Nudie</h1>
                    <div className='selectNudieHolder'>
                        <Card handler={setChosenNudie} props={imagesArray}/>   
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Inventory;




    // function TraitList(){
    //     //console.log(traits)
    //     const traitTypes = traits.traitType

    //     const traitTypeList = traitTypes.map(({traitType}, index) =>
    //         <li key={index}>
    //             {traitType}
    //         </li>
    //     );
    //     return (
    //         <ul>{traitTypeList}</ul>
    //     );
    // }
    // const valueList = traits.map(({ value }, index) => {

    //     return (
    //         <li key={index}>

    //         </li>
    //     );
    // });