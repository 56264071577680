export const GetTraitsOfJson = async (props) => {
    var traitsArray = []
    let fetchedJson
    let json = "https://nudies.s3.us-west-1.amazonaws.com/Assets/json/" + props + ".json"
    try {
        await fetch(json)
        .then((response) => response.json())
        .then((actualData) => (fetchedJson = actualData))
        .catch((err) => {
            console.log(err.message);
        });


        for (let i = 0; i < fetchedJson.attributes.length; i++) {
            traitsArray.push(
                {
                    "traitType": fetchedJson.attributes[i].trait_type,
                    "value": fetchedJson.attributes[i].value,                     
                }
            )     
            
        }

    } catch (error) {
        console.log(error)
    }

    return traitsArray
}