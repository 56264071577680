import React, { useState, useEffect, useRef } from 'react'; 
import {filter, utils} from "ethers";
import CardTraits from "../../Utils/Cards/Card"
import Card from '../../Utils/Cards/Card';
import { Button } from 'react-bootstrap'
import "./TraitSwap.css"
import { getCurrentWalletConnected, walletConnectWallet, clearWalletConnectWallet } from "./../../Utils/Wallet/Interact";
import { fetchOwnCollection, loadStakedNudies } from '../../Utils/APIs/BlockChainAPIs';
import {Redirect, Route} from "react-router-dom";
import { getConnectedStakingContract, getConnectedERC721Contract, getConnectedERC20Contract } from '../../Utils/Contracts/ContractConnections';
//import coin from "./../../Images/coin.png"
import coin from "./../../Images/cashnipcoin.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Snackbar from "./../../Utils/Snackbar/Snackbar";
import logo from "../../Images/nudieLogo.png"
import {TraitSwapDropdown} from "./TraitSwapDropdown"
import {GetTraitsOfJson} from "../TraitIndexes/TraitIndexes"
import { ClosetSelector } from "./ClosetSelector";
import { ClosetSelectorBubble } from "./ClosetSelectorBubble";
import SignMessageInput from "../../Utils/Blockchain/SignMessage"
import {SendRequestAPIPost} from "../../Utils/APIs/BackEndAPIs"
import {ProposalArray} from "./Requests/RequestsContainer"
import { RequestsContainer } from './Requests/RequestsContainer';
import {FindNFTOwnerAddress, GetTrueNFTOwner, GetNFTApiReponse} from "./FindOwner/FindOwner"
import { v4 as uuidv4 } from 'uuid';
import allowedWallets from "./../allowedWallets.json"

require('dotenv').config()
AOS.init();

const APIKEY1 = process.env.REACT_APP_KEY1;

const SnackbarType = {
    success: "success",
    fail: "fail",
};


const TraitSwap = () => {
    const snackbarRef = useRef(null);
    const [snackBarType, SetSnackBarType] = useState(SnackbarType.fail)
    const [snackBarMessage, SetSnackBarMessage] = useState("")

    const [chosenNudie, setChosenNudie] = useState("0")
    const [showChosenNudie, setShowChosenNudie] = useState("none")

    //trait selecotr hooks
    const [nudieTraits, setNudieTraits] = useState([])
    const [nudieTraitsNudie2, setNudieTraitsNudie2] = useState([])
    const [displayBox, setDisplayBox] = useState("none")

    const [requestArray, setRequestArray] = useState([])


    //picture handling hooks
    const [chosenSwapNudieImage, setChosenSwapNudieImage] = useState(logo)
    const [chosenImage, setChosenImage] = useState([])
    const [chosenSwapNudieNumber, setChosenSwapNudieNumber] = useState("")
    const [chosenSwapNudieNumberTitle, setChosenSwapNudieNumberTitle] = useState("Please Enter Below")
    const [chosenSwapNudieNumberMobileTitle, setChosenSwapNudieNumberMobileTitle] = useState("Please Enter Above")
    const [chosenNudieImage, setChosenNudieImage] = useState("")
    const [traitReturnValue, setTraitReturnValue] = useState([])

    const [inventoryTraitImagesArray, setInventoryTraitImagesArray] = useState([])

    const [showInventoryBox, setShowInventoryBox] = useState("none");

    const [openseaAPIFailure, setOpenseaAPIFaliure] = useState(false)


    //login hooks
    const [displayWallet, setDisplayWallet] = useState("");
    const [logBackIn, setLogBackIn] = useState(false);
    const [showInfo, setShowInfo] = useState("none");
    const [showInvalidSwapInfo, setShowInvalidSwapInfo] = useState("none");

    //wallet owner hooks
    const [selectedNudieOwner, setSelectedNudieOwner] = useState("");

    var imagesArray = []
    var nudieTradedArray = []
    var wallet2 = ""
    var traits = []

    const { toChecksumAddress } = require('ethereum-checksum-address')
    const [layers, setLayers] = useState([])
    const [values, setValues] = useState([])
    const [selectedLayer, setSelectedLayer] = useState([])
    const [selectedValue, setSelectedValue] = useState([])
    const [selectedLayerNudie2, setSelectedLayerNudie2] = useState([])
    const [selectedValueNudie2, setSelectedValueNudie2] = useState([])

    var traitImagesArray = []
    var traitImagesArrayLayers = []
    var traitImagesArrayValues = []

    // const [hideAll, setHideAll] = useState("block")
    // useEffect(async () => {
    //     let wallet = await (await getCurrentWalletConnected()).address
    //     let matched = false

    //     for (let i = 0; i < allowedWallets.length; i++) {
    //         if(toChecksumAddress(wallet) == allowedWallets[i].walletID){
    //             matched = true
    //             setHideAll("block")
    //         }
    //     }
    // }, []);

    useEffect(async () => {
        RetrieveClosetItems();
    }, [])

    const RetrieveClosetItems = async () => {
        var wallet2 =  await (await getCurrentWalletConnected()).address
        var closetUrl = "https://nudies.s3.us-west-1.amazonaws.com/Closet/" + toChecksumAddress(wallet2) + ".json"

        try {
            fetch(closetUrl)
            .then((response) => response.json())
            .then((actualData) => loadUserClosetItems(actualData))
            .catch((err) => {
                ////console.log(err.message);
            });

        } catch (err) {
           // //console.log(err)
        }
    }

    const loadUserClosetItems = (actualData) =>{
        if(actualData.attributes.length != null){
            for(var i = 0; i < actualData.attributes.length; i++){
                traitImagesArray.push(
                    {
                        "traitType": actualData.attributes[i].trait_type,
                        "value": actualData.attributes[i].value,
                        "imageUrl": actualData.attributes[i].image_url,                        
                    }
                )      
                traitImagesArrayLayers.push(
                    {
                        "traitType": actualData.attributes[i].trait_type,
                    }
                )     
                traitImagesArrayValues.push(
                    {
                        "value": actualData.attributes[i].value,
                    }
                )        
            }      
        }
        setLayers(traitImagesArrayLayers)
        setValues(traitImagesArrayValues)
        ////console.log(traitImagesArrayLayers)
        ////console.log(traitImagesArrayValues)

    }
      
    useEffect(async () => {
        if(chosenNudie == 0){
            setShowChosenNudie("block")
            setChosenNudieImage(logo)
        }
        if(chosenNudie > 0 && chosenNudie < 10001){
            setShowChosenNudie("block")
            traits = await GetTraitsOfJson(chosenNudie)
            SetImages()
        }
        return () => {
            setShowChosenNudie("block")
            setChosenNudieImage("https://nudies.s3.us-west-1.amazonaws.com/Assets/img/" + chosenNudie + ".png")
            setNudieTraits(traits)
        };
    });

    const SetImages = async () => {
        setShowChosenNudie("block")
        setChosenNudieImage("https://nudies.s3.us-west-1.amazonaws.com/Assets/img/" + chosenNudie + ".png")
        setNudieTraits(traits)
    }

    useEffect(async () => {
        wallet2 = await (await getCurrentWalletConnected()).address
        if(!wallet2){wallet2 = await (await walletConnectWallet()).address}
        setDisplayWallet(wallet2)
        //console.log(wallet2)
        try{
            // fetchOwnCollection()
            // .then((response) => RenderNudies(response))
            // .catch((err) => {
            //     //console.log(err.message);
            //     setOpenseaAPIFaliure(true)
                // SetSnackBarMessage("Opensea API request timeout, please reload page. Fallback API loaded")
                // SetSnackBarType(SnackbarType.fail)
                // snackbarRef.current.show()  
                fetch("https://api.etherscan.io/api?module=account&action=tokennfttx&contractaddress=0x89Bd11D1fBa7906464fa11f3086fE8ba1B5368A1&address=" + wallet2 + "&offset=100&startblock=0&endblock=27025780&sort=asc&apikey=T7U7YX32H4KYIBBJ1NT6BVJWZHWQQ7NY4B", {cache: "no-store"})
                .then((response) => response.json())
                .then((actualData) => loadUserNudies(actualData))
                .catch((err) => {
                    //console.log(err.message);
                });
            //});
            loadStakedNudies()
            return () => {
            };
        }catch(err){
            //console.log(err)
        }
    }, []);

    useEffect(async () => {
        wallet2 = await (await getCurrentWalletConnected()).address
        try{
            fetch("https://nudies.s3.us-west-1.amazonaws.com/Requests/" + toChecksumAddress(wallet2) + ".json", {cache: "no-store"})
            .then((response) => response.json())
            .then((data) => setRequests(data))
            .catch((err) => {
                ////console.log(err.message);
            });
            return () => {
            };
        }catch(err){
            //console.log(err)
        }
    }, []);


    async function setRequests(data){
        let requests = []
        //console.log(data)

        for (let i = 0; i < data.requests.length; i++) {
            requests.push(data.requests[i])    
        }

        setRequestArray(requests)        
    }

    async function RenderNudies(response){
        if(response.length != null){
            for(var i = 0; i < response.length; i++){
                imagesArray.push(
                    {
                        "ID": response[i],
                        "Image": "https://nudies.s3.us-west-1.amazonaws.com/Assets/img/" + response[i] + ".png",
                        "Colour": "",                        
                    }
                )         
            }      
            setChosenImage(imagesArray)            
        }
    }

    async function loadUserNudies(actualData){
        let match = false;
        let matchFromStaker = false;
        const loadWallet = wallet2;
        var nudieMatchArray = []
        try{
            for (let j = 0; j < actualData.result.length; j++) {
                if(actualData.result[j].from == loadWallet || toChecksumAddress(actualData.result[j].from.toString()) == loadWallet){
                    for (let o = j; o < actualData.result.length; o++) {

                        if(actualData.result[o].from == "0x8dd465df3d1d4e0a4ae978fa7fb30eb558ab8714" || actualData.result[o].to == "0x8dd465df3d1d4e0a4ae978fa7fb30eb558ab8714"){
                            if(actualData.result[o].tokenID == actualData.result[j].tokenID){
                                matchFromStaker = true;                                
                            }
                        }

                        if(actualData.result[o].to == loadWallet || toChecksumAddress(actualData.result[o].to.toString()) == loadWallet){
                            if(actualData.result[o].tokenID == actualData.result[j].tokenID){
                                matchFromStaker = true;                                
                            }                        
                        }
                    }
                    if(matchFromStaker == false){
                        nudieTradedArray.push(actualData.result[j].tokenID)
                    }
                    matchFromStaker = false
                } 
            }   
            for(var i = 0; i < actualData.result.length; i++){
                match = false;
                    for (let l = 0; l < nudieMatchArray.length; l++) {
                        if(nudieMatchArray[l] == actualData.result[i].tokenID) {
                            match = true;
                        }  
                    }                  
                    if(match == false){

                        if (nudieTradedArray.indexOf(actualData.result[i].tokenID) == -1)
                            {
                                imagesArray.push(
                                    {
                                        "ID": actualData.result[i].tokenID,
                                        "Image": "https://nudies.s3.us-west-1.amazonaws.com/Assets/img/" + actualData.result[i].tokenID + ".png",
                                        "Colour": "",                        
                                    }
                                )  
                            }
                    }
                    nudieMatchArray.push(actualData.result[i].tokenID)
            }
        }catch{
            //console.log("Images Cant Load")
        }
        setChosenImage(imagesArray)
    }

    async function addTraitsToSwap(){
        setDisplayBox("block")
    }

    async function removeTrait(){
        showRemoveInventoryBox()
    }

    function showRemoveInventoryBox(){
        setShowInventoryBox("block")
    }

    const loadSelectedNudie = async event => {
        setChosenSwapNudieNumber(event.target.value)
        var traitsNudie2 = await GetTraitsOfJson(event.target.value)
        setNudieTraitsNudie2(traitsNudie2)
    }

    async function loadSwapNudie(){
        if(chosenSwapNudieNumber > 0 && chosenSwapNudieNumber < 10001 && !isNaN(chosenSwapNudieNumber)){
            setChosenSwapNudieNumberTitle(chosenSwapNudieNumber)
            setChosenSwapNudieNumberMobileTitle(chosenSwapNudieNumber)
            setChosenSwapNudieImage("https://nudies.s3.us-west-1.amazonaws.com/Assets/img/" + chosenSwapNudieNumber + ".png")
            let owner = await FindNFTOwnerAddress(chosenSwapNudieNumber)
            if(owner == "0x8Dd465DF3D1d4E0a4ae978FA7fb30Eb558AB8714"){
                let data = await GetNFTApiReponse(chosenSwapNudieNumber)
                //console.log(data)
                owner = await GetTrueNFTOwner(chosenSwapNudieNumber, data)
            }
            //setSelectedNudieOwner(toChecksumAddress(owner))            
        }else{
            SetSnackBarMessage("Please select a valid nudie number")
            SetSnackBarType(SnackbarType.fail)
            snackbarRef.current.show()  
        }
    }

    function isNumeric(value) {
        return /^\d+$/.test(value);
    }

    async function sendRequest(){
        var singedResponse = ""
        var guuid = uuidv4();
        var wallet = await (await getCurrentWalletConnected()).address

        var validSwap = checkSwapItems(selectedLayer, selectedLayerNudie2)

        //if(selectedNudieOwner.length < 1){
        let backupSelectedNudieOwner = await FindNFTOwnerAddress(chosenSwapNudieNumber)
        if(backupSelectedNudieOwner == "0x8Dd465DF3D1d4E0a4ae978FA7fb30Eb558AB8714"){
            let data = await GetNFTApiReponse(chosenSwapNudieNumber)
            backupSelectedNudieOwner = await GetTrueNFTOwner(chosenSwapNudieNumber, data)
        }   
            //setSelectedNudieOwner(backupSelectedNudieOwner)         
        //}

        if(validSwap && selectedNudieOwner != "0x8Dd465DF3D1d4E0a4ae978FA7fb30Eb558AB8714" && backupSelectedNudieOwner.length > 0){
            //console.log(inventoryTraitImagesArray, selectedLayer, selectedValue, chosenNudie, chosenSwapNudieNumberTitle)
            if(selectedLayer.length < 1 || selectedValue.length < 1 || selectedValue == "Trait" || chosenNudie < 1 || chosenSwapNudieNumberTitle < 1
            || isNumeric(chosenSwapNudieNumberTitle) == false || selectedLayerNudie2.length < 1 || selectedValueNudie2.length < 1 || selectedValueNudie2 == "Trait") {
                SetSnackBarMessage("Please select: Owned Nudie, Nudie to swap with and a Trait to swap for both")
                SetSnackBarType(SnackbarType.fail)
                snackbarRef.current.show()  
            }else{
                const JSONString = JSON.stringify({ 
                                    Function: "TraitSwap",
                                    GUUID: guuid,
                                    InitialSwapWallet: toChecksumAddress(wallet),
                                    SwapRequestWallet: toChecksumAddress(backupSelectedNudieOwner),
                                    InventoryItems: inventoryTraitImagesArray, 
                                    SwapNudieFrom: chosenNudie,
                                    SwapNudieToo: chosenSwapNudieNumberTitle,
                                    SelectedLayer: selectedLayer,
                                    SelectedValue: selectedValue,
                                    SelectedSwapeeLayer: selectedLayerNudie2,
                                    SelectedSwapeeValue: selectedValueNudie2
                                })

                singedResponse = await SignMessageInput(JSONString)   
                
                if(singedResponse.signature.length < 1){
                    SetSnackBarMessage("Singnature Declined")
                    SetSnackBarType(SnackbarType.fail)
                    snackbarRef.current.show()                  
                }else{
                    const responseStatus = await SendRequestAPIPost(singedResponse) 
                    
                    if(responseStatus == 200){
                        SetSnackBarMessage("Request Sent")
                        SetSnackBarType(SnackbarType.success)
                        snackbarRef.current.show()  
                    }
                    if(responseStatus == 500){
                        const secondResponseStatus = await SendRequestAPIPost(singedResponse)  

                        if(secondResponseStatus == 500){    
                            SetSnackBarMessage("Request Failed Please Try Again")
                            SetSnackBarType(SnackbarType.fail)
                            snackbarRef.current.show()  
                        }
                        if(secondResponseStatus == 200){
                            SetSnackBarMessage("Request Sent")
                            SetSnackBarType(SnackbarType.success)
                            snackbarRef.current.show()  
                        }
                    }                    
                }
                
        
            }
        }else{
            SetSnackBarMessage("Sender wallet now found")
            SetSnackBarType(SnackbarType.fail)
            snackbarRef.current.show()  
        }
        //console.log(singedResponse)
    }

    function checkSwapItems(selectedLayer, selectedLayerNudie2){

        if(selectedLayer == selectedLayerNudie2){
            return true
        }else{

            if(selectedLayer == "Background" || selectedLayer == "Skins" || selectedLayer == "Eyes" ||
                selectedLayer == "Mouth" || selectedLayerNudie2 == "Background" || selectedLayerNudie2 == "Skins" || 
                selectedLayerNudie2 == "Eyes" || selectedLayerNudie2 == "Mouth")
            {
                setShowInvalidSwapInfo("block")
                return false
            }else{
                return true
            }

        }
    }

    async function loadRequests(){
        // var wallet = await (await getCurrentWalletConnected()).address
        // try{
        //     fetch("https://nudies.s3.us-west-1.amazonaws.com/Requests/" + toChecksumAddress(wallet) + ".json")
        //     .then((response) => response.json())
        //     .then((actualData) => setRequestArray(actualData))
        //     .catch((err) => {
        //         //console.log(err.message);
        //     });
        //     return () => {
        //     };
        // }catch(err){
        //     //console.log(err)
        // }
    }

    async function LogBackIn(){
        setLogBackIn(true)
        wallet2 = null;
    }

    function hideInfoBox(){
        setShowInfo("none")
        setShowInvalidSwapInfo("none")
    }

    function showInfoBox(){
        setShowInfo("block")
    }

    return(
        // <div className='comingSoon'>
        //     <h1>COMING SOON</h1>    
        // </div>
        <div className='TraitSwap'>
                <Snackbar
                    ref={snackbarRef}
                    message={snackBarMessage}
                    type={snackBarType}
                />
            {/* <ClosetSelector prop2={displayBox}  props={layers} props3={values} handler={setDisplayBox} traitRetunValue={setTraitReturnValue}/>      */}
            <ClosetSelectorBubble prop2={displayBox} handler={setDisplayBox} setAddedTraitList={setInventoryTraitImagesArray}/>
            <div className='infoPannelBK' style={{
                    display:showInfo
                }}>      
                <div className='infoPannel'>
                    <div className='infoPannelHeader'>
                        <h1>Trait Swap Information</h1>
                        <button onClick={hideInfoBox} className='closeInfo fa fa-times'>
                        </button>
                    </div>
                    <div className='infoPannelFooter'>
                        <div className='stakeInfo'>
                            <p>Select one of your own Nudies, enter a Nudie to swap with, and select a trait from both yours and the other chosen nudie to swap.
                            </p>
                            <p>You may add any of you inventory items to sweeten the deal with the 'Add Inventory Items' button.</p>
                            <p>Once all fields are selected you can send the request for the other user to accept.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='infoPannelBK' style={{
                    display:showInvalidSwapInfo
                }}>      
                <div className='infoPannel'>
                    <div className='infoPannelHeader'>
                        <h1>Invalid Swap</h1>
                        <button onClick={hideInfoBox} className='closeInfo fa fa-times'>
                        </button>
                    </div>
                    <div className='infoPannelFooter'>
                        <div className='stakeInfo'>
                            <p>None matching trait swaps will remove the item from both Nudies, then place the swapped traits into the users closet.</p>
                            <p>Certain traits if removed will break the integrity of the Nudie image, traits effected:</p>
                            <p>Background, Skin, Mouth, Eyes</p>
                        </div>
                    </div>
                </div>
            </div>
            <div data-aos="fade-left" className='topContainer'>
                <div className='infoContainer'>
                    <Button className='walletConnected' onClick={LogBackIn}>
                        {displayWallet.length > 0 ? (
                        String(displayWallet).substring(0, 4) +
                        "..." +
                        String(displayWallet).substring(39)
                        ) : (
                        <span>Wallet Error</span>
                        )}
                    <Route exact path="/Staking" render={() => (
                        logBackIn ? (
                            <Redirect to="/"/>
                        ) : (
                            <Redirect to="/Staking"/>
                        )
                        )}/>
                    </Button>
                    <Button className='walletConnected' onClick={showInfoBox}>
                        Trait Swap Info
                    </Button>
                </div>
            </div>
            <div className='traitSwapContainer'>
                <div data-aos="fade-right" className='leftContainer'>
                    <div className='TraitSwapTopContianer'>
                        <h1>Selected Owned Nudie</h1>
                        <div className='chosenNudieHolder'>
                            <img
                                style={{display : showChosenNudie}} 
                                src={chosenNudieImage}/>
                        </div>
                        <h1>#{chosenNudie}</h1>
                    </div>
                    <div className='selectNudieContainerTraitSwap'>   
                        <div className='selectNudieContianerTraitSwapMobile'>
                            <h1>Select Nudie</h1>
                            <div className='selectNudieHolderTraitSwap'>
                                <Card handler={setChosenNudie} props={imagesArray}/>   
                            </div>
                        </div>
                    </div>
                </div>
                <div data-aos="fade-up" className='middleTraitSwapContainer'>
                    <div className='traitSwapControls'>
                        <div className='ownedNudieDropdownBox'>
                            <h3>Owned Nudie Trait</h3>
                            <div className='ownedTraitSwapDropdownHolder'>
                                <TraitSwapDropdown props={nudieTraits} returnSelectedLayer={setSelectedLayer} returnSelectedValue={setSelectedValue}/>
                            </div>
                            <h3>Trait To Swap With</h3>
                            <div className='traitSwapDropdownHolder'>
                                <TraitSwapDropdown props={nudieTraitsNudie2} returnSelectedLayer={setSelectedLayerNudie2} returnSelectedValue={setSelectedValueNudie2}/>
                            </div>
                        </div>
                        <Button className='addInventoryButton' onClick={addTraitsToSwap}>Add Inventory Items</Button>
                        <Button className='sendRequestButton' onClick={sendRequest}>Send Request</Button>
                    </div>
                </div>
                <div className='NudieToSwapContainer'>
                    <h1 className='chosenSwapNudieNumberTitleMobile'>Enter Nudie # To Swap With</h1>
                    <div className='loadTraitSwapNudieContainer'>
                        <input className='loadTraitSwapNudieInput' placeholder='Search Nudie...'  onChange={loadSelectedNudie} value={chosenSwapNudieNumber}></input>
                        <button className='loadTraitSwapNudieButton' onClick={loadSwapNudie}>Load</button>
                    </div>
                </div>
                <div data-aos="fade-left" className='rightContainer'>
                    <div className='TraitSwapTopContianer'>
                        <h1>Nudie To Swap With</h1>
                        <div className='chosenNudieHolder'>
                            <img
                                style={{display : showChosenNudie}} 
                                src={chosenSwapNudieImage}/>
                        </div>
                        <h1 className='chosenSwapNudieNumberTitle'>#{chosenSwapNudieNumberTitle}</h1>
                        <h1 className='chosenSwapNudieNumberTitleMobile'>#{chosenSwapNudieNumberMobileTitle}</h1>
                    </div>
                </div>
            </div>
            <div className='bottomTraitSwapContainer'>
                <div className='bottomTraitSwapLeft'>
                    <div className='selectNudieContianerTraitSwap'>
                        <h1>Select Nudie</h1>
                        <div className='selectNudieHolderTraitSwap'>
                            <Card handler={setChosenNudie} props={imagesArray}/>   
                        </div>
                    </div>
                </div>
                <div className='bottomTraitSwapRight'>
                    <div className='loadTraitSwapNudieContainer'>
                        <input className='loadTraitSwapNudieInput' placeholder='Search Nudie...'  onChange={loadSelectedNudie} value={chosenSwapNudieNumber}></input>
                        <button className='loadTraitSwapNudieButton' onClick={loadSwapNudie}>Load</button>
                    </div>
                </div>
            </div>
            <div className='requestsTraitSwapContainer'>
                <div className='bottomTraitSwapBottomLeft'>
                    <div className='requestTraitSwap'>
                        <h1>Requests</h1>
                        <div className='requestsContianerTraitSwap'>
                            <RequestsContainer></RequestsContainer>
                        </div>
                    </div>
                </div>
                {/* <div className='bottomTraitSwapBottomRight'>
                    <div className='loadTraitSwapNudieContainer'>
                        <button className='loadTraitSwapRequestsButton' onClick={loadRequests}>Load Requests</button>
                    </div>
                </div> */}
            </div>
            <div className='requestsTraitSwapContainerMobile'>
                {/* <div className='bottomTraitSwapBottomLeft'>
                    <div className='requestTraitSwap'>
                        <div className='loadTraitSwapNudieContainer'>
                            <button className='loadTraitSwapRequestsButton' onClick={loadRequests}>Load Requests</button>
                        </div>
                    </div>
                </div> */}
                <div className='bottomTraitSwapBottomRight'>
                    <h1>Requests</h1>
                    <div className='requestsContianerTraitSwap'>
                        <RequestsContainer></RequestsContainer>
                    </div>
                </div>
            </div>
            <div className='footer '>
            </div>   
        </div>
    )
}

export default TraitSwap;