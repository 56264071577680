import {ItemSection} from "./ItemSection"
import logo from "../../Images/nudieLogo.png"

import "./Shop.css"

export const ItemSectionArray = ({shopItemArray, setShopIsLoading}) =>{//, chosenShopItemHandler}) =>{

    return(
        <div className="shopRow">
            {shopItemArray.slice(0).reverse().map(function(items, index){
                return (<ItemSection 
                            key={index}
                            ID={items.ID}
                            itemSKU={items.itemSKU}
                            trait_type={items.trait_type}
                            value={items.value}
                            itemName={items.name}
                            price={items.price}
                            imageUrl={items.imageUrl}
                            quantity={items.quantity}
                            setShopIsLoading={setShopIsLoading}
                            //chosenItem={chosenShopItemHandler}
                        />)
                })}
        </div>
    )
}