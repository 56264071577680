import "./Voting.css"
import SignMessageInput from "../../Utils/Blockchain/SignMessage"
import {SendVoteAPIPost} from "../../Utils/APIs/BackEndAPIs"
import { getCurrentWalletConnected } from "./../../Utils/Wallet/Interact";
import Snackbar from "./../../Utils/Snackbar/Snackbar";
import React, { useState, useEffect, useRef } from 'react'; 

const SnackbarType = {
    success: "success",
    fail: "fail",
};

const { toChecksumAddress } = require('ethereum-checksum-address')


export const ProposalsButton = ({title, answer, votingPower, userVoted, updateResult}) =>{

    const [hideButton, setHideButton] = useState("none")
    const snackbarRef = useRef(null);
    const [snackBarType, SetSnackBarType] = useState(SnackbarType.fail)
    const [snackBarMessage, SetSnackBarMessage] = useState("")

    useEffect(async () => {
        if(answer.length > 0){
            setHideButton("block")
        }
    }, []);

    async function sendVote(){
        if(votingPower > 0){
            const wallet = await (await getCurrentWalletConnected()).address
            const checkedWallet = toChecksumAddress(wallet)

            const JSONString = JSON.stringify({ 
            function: "SendVote",
            wallet: checkedWallet,
            title: title,
            answer: answer,
            votes: votingPower})

            const singedResponse = await SignMessageInput(JSONString)
            const responseStatus = await SendVoteAPIPost(singedResponse) 

            if(singedResponse.signature.length > 0){
                userVoted(true)
            }
            if(responseStatus == 200 && singedResponse.signature.length > 0){
                SetSnackBarMessage("Vote Sent")
                SetSnackBarType(SnackbarType.success)
                snackbarRef.current.show()  
                setTimeout(() => {
                    userVoted(true)
                }, 2000);
                updateResult(true)
            }
            if(responseStatus == 500){
                var secondResponseStatus = 500
                while(secondResponseStatus == 500){
                    secondResponseStatus = await SendVoteAPIPost(singedResponse)  
                }

                if(secondResponseStatus == 200 && singedResponse.signature.length > 0){
                    document.getElementById("openVote").style.display = "none"
                    SetSnackBarMessage("Vote Sent")
                    SetSnackBarType(SnackbarType.success)
                    snackbarRef.current.show() 
                    setTimeout(() => {
                        userVoted(true)
                    }, 2000);
                    updateResult(true)
                }
            }

            setTimeout(() => {
                updateResult(true)
            }, 2000);

        }else{
            alert("User Cannot Vote")
        }

    }

    return(
        <div className="buttonFlexContainer">
            <div className="voteContainer">
                <Snackbar
                    ref={snackbarRef}
                    message={snackBarMessage}
                    type={snackBarType}
                />
                <button style={{display: hideButton}} className="voteButton" onClick={sendVote}>{answer}</button>
            </div>
        </div>
    )
}