import React, { useState, useEffect, useRef } from 'react'; 
import {filter, utils} from "ethers";
import { getCurrentWalletConnected} from "./../../../Utils/Wallet/Interact";
import { getConnectedERC721Contract } from '../../../Utils/Contracts/ContractConnections';
const { toChecksumAddress } = require('ethereum-checksum-address')


export const FindNFTOwnerAddress = async (nudieNumber) => {
    var wallet = ""
    var ownerAddress = ""
    var data

    //console.log("finding owner")

    try{ 
        const connectedERC721Contract = await getConnectedERC721Contract()
        wallet = await (await getCurrentWalletConnected()).address
        if(!connectedERC721Contract) return;
            try {
                ownerAddress = await connectedERC721Contract.ownerOf(nudieNumber)
                //console.log("Owner of" + nudieNumber + " " + ownerAddress)

            } catch (error) {
                console.log(error) 
            }
        
    } catch (err){
        console.log(err.message);
    }

    // if(ownerAddress == "0x8Dd465DF3D1d4E0a4ae978FA7fb30Eb558AB8714"){
    //     console.log("Owner is Staking contract")

    //     fetch("https://api.etherscan.io/api?module=account&action=tokennfttx&contractaddress=0x89Bd11D1fBa7906464fa11f3086fE8ba1B5368A1&address=0x8Dd465DF3D1d4E0a4ae978FA7fb30Eb558AB8714&offset=100&startblock=0&endblock=27025780&sort=asc&apikey=T7U7YX32H4KYIBBJ1NT6BVJWZHWQQ7NY4B")
    //     .then((response) => response.json())
    //     .then((actualData) => filterOwner(actualData))
    //     .catch((err) => {
    //         console.log(err.message);
    //     });

    //     const filterOwner = (actualData) =>{
    //         var newArray = actualData.result.filter(function (el)
    //         {
    //             return el.tokenID == nudieNumber
    //         }
    //         );
    //         ownerAddress = newArray[newArray.length - 1].from
    //         console.log(ownerAddress)
    //     }


    //     return ownerAddress
    // }else{
        return ownerAddress
    //}
}

export const GetNFTApiReponse = async (nudieNumber) => {
    return fetch("https://api.etherscan.io/api?module=account&action=tokennfttx&contractaddress=0x89Bd11D1fBa7906464fa11f3086fE8ba1B5368A1&address=0x8Dd465DF3D1d4E0a4ae978FA7fb30Eb558AB8714&offset=100&startblock=0&endblock=27025780&sort=asc&apikey=T7U7YX32H4KYIBBJ1NT6BVJWZHWQQ7NY4B")
    .then((response) => response.json())
    .then((actualData) => {return actualData})
    .catch((err) => {
        console.log(err.message);
    });
}

export const GetTrueNFTOwner = async (nudieNumber, data) => {
    //console.log("Owner is Staking contract")

    let ownerAddress = ""

    // fetch("https://api.etherscan.io/api?module=account&action=tokennfttx&contractaddress=0x89Bd11D1fBa7906464fa11f3086fE8ba1B5368A1&address=0x8Dd465DF3D1d4E0a4ae978FA7fb30Eb558AB8714&offset=100&startblock=0&endblock=27025780&sort=asc&apikey=T7U7YX32H4KYIBBJ1NT6BVJWZHWQQ7NY4B")
    // .then((response) => response.json())
    // .then((actualData) => filterOwner(actualData))
    // .catch((err) => {
    //     console.log(err.message);
    // });

    // const filterOwner = (actualData) =>{

    // var newArray = data.result.filter(function (el)
    // {
    //     return el.tokenID == nudieNumber
    // }
    // );

    //ownerAddress = newArray[newArray.length - 1].from
    // }

    const newArray = data.result.filter(item => item.tokenID == nudieNumber)
    ownerAddress = newArray[newArray.length - 1].from

    return ownerAddress
}

function filterByValue(array, value) {
    return array.filter((data) =>  JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1);
}

// async function loadUserNudies(actualData){
//     let match = false;
//     let matchFromStaker = false;

//     //const loadWallet = (await wallet).address
//     const loadWallet = wallet2;
//     //const loadWallet = "0xd3c5b9af0f6a459746e15ea4d744fe5fbe6b75c2"

//     var nudieMatchArray = []
//     //console.log(actualData.result)

//     try{
//         for (let j = 0; j < actualData.result.length; j++) {
//             //console.log(actualData.result[j].from + "  " + loadWallet)
//             if(actualData.result[j].from == loadWallet || toChecksumAddress(actualData.result[j].from.toString()) == loadWallet){
//               //console.log("from:")
//                 //console.log(actualData.result[j])
//                 for (let o = j; o < actualData.result.length; o++) {

//                     if(actualData.result[o].from == "0x8dd465df3d1d4e0a4ae978fa7fb30eb558ab8714" || actualData.result[o].to == "0x8dd465df3d1d4e0a4ae978fa7fb30eb558ab8714"){
//                         if(actualData.result[o].tokenID == actualData.result[j].tokenID){
//                             //console.log(actualData.result[o].to + actualData.result[o].from)
//                             matchFromStaker = true;                                
//                         }
//                     }

//                     if(actualData.result[o].to == loadWallet || toChecksumAddress(actualData.result[o].to.toString()) == loadWallet){
//                         if(actualData.result[o].tokenID == actualData.result[j].tokenID){
//                             //console.log(actualData.result[o].to + actualData.result[o].from)
//                             matchFromStaker = true;                                
//                         }                        
//                     }
//                 }
//                 if(matchFromStaker == false){
//                     nudieTradedArray.push(actualData.result[j].tokenID)
//                 }
//                 matchFromStaker = false
//             } 
//         }   
//         //console.log(nudieTradedArray)


//         for(var i = 0; i < actualData.result.length; i++){

            
//             //console.log(actualData.result[i].to)
//             match = false;
//                 //if(actualData.result[i].to == loadWallet){
//                 //}
//                 for (let l = 0; l < nudieMatchArray.length; l++) {
//                     if(nudieMatchArray[l] == actualData.result[i].tokenID) {
//                         match = true;
//                     }  
//                 }
//                 // match = false;
//                 // for (let j = 0; j < actualData.result.length; j++) {
//                 //     if(actualData.result[j].to != (await wallet).address){
//                 //         nudieMatch.push(actualData.result[j].tokenID)
//                 //         console.log(nudieMatch)
//                 //     }
//                 // }
//                 // for (let k = 0; k < nudieMatch.length; k++) {
//                 //     if(nudieMatch[k] == actualData.result[i].tokenID){
//                 //         match = true
//                 //     } 
//                 // }
//                 // if(match == false){
//                 //     imagesArray.push(
//                 //         {
//                 //             "ID": actualData.result[i].tokenID,
//                 //             "Image": "https://nudiecommunity.mypinata.cloud/ipfs/QmXAXsvjKpVE8Ar2EhCSm5MvFMAWe6pnXNzkAra3ekDsnD/" + actualData.result[i].tokenID + ".png",
//                 //             "Colour": "",                        
//                 //         }
//                 //     )  
//                 //     setChosenNudie(actualData.result[i].tokenID)   
//                 // }                    
//                 if(match == false){

//                     if (nudieTradedArray.indexOf(actualData.result[i].tokenID) == -1)
//                         {
//                             imagesArray.push(
//                                 {
//                                     "ID": actualData.result[i].tokenID,
//                                     "Image": "https://nudiecommunity.mypinata.cloud/ipfs/QmXAXsvjKpVE8Ar2EhCSm5MvFMAWe6pnXNzkAra3ekDsnD/" + actualData.result[i].tokenID + ".png",
//                                     "Colour": "",                        
//                                 }
//                             )  
//                         }

//                     // imagesArray.push(
//                     //     {
//                     //         "ID": actualData.result[i].tokenID,
//                     //         "Image": "https://nudiecommunity.mypinata.cloud/ipfs/QmXAXsvjKpVE8Ar2EhCSm5MvFMAWe6pnXNzkAra3ekDsnD/" + actualData.result[i].tokenID + ".png",
//                     //         "Colour": "",                        
//                     //     }
//                     // )  
//                 }
//                 nudieMatchArray.push(actualData.result[i].tokenID)
//                 //     setChosenNudie(actualData.result[i].tokenID)   
//                 // }
//             //}
//         }
//     }catch{
//         console.log("Images Cant Load")
//     }
//     //console.log(imagesArray)
//     setChosenImage(imagesArray)
// }