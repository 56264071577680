import './App.css';
import './Utils/Navbar/Navbar.css'
import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Navbar from "./Utils/Navbar/Navbar"
import AnimatedRoutes from './Utils/Routing/AnimatedRoutes';
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from "@ethersproject/providers";

function getLibrary(provider) {
  return new Web3Provider(provider);
}
function App() {
  // const location = useLocation();

  return (
    <div className='background'>
      <Router>
        <Navbar />
        <Web3ReactProvider getLibrary={getLibrary}>
        <AnimatedRoutes/>
        </Web3ReactProvider>
        {/* <Footer/> */}
      </Router>
    </div>
  );
}

export default App;
