import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Staking from "../../Components/Staking/Staking";
import Shop from "../../Components/Shop/Shop"
import TraitSwap from "../../Components/TraitSwap/TraitSwap"
import Inventory from "../../Components/Inventory/Inventory"
import LoginScreen from "../../Components/LoginScreen/LoginScreen"
import Voting from "../../Components/Voting/Voting"

import { AnimatePresence } from 'framer-motion';

function AnimatedRoutes() {
    const location = useLocation();

    return(
        <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
                {/* <Route path="/Return" exact component={() => { 
                    window.location.href = 'https://www.nudiecommunity.io/'; 
                    return null;}}/> */}
                <Route path="/" exact component={LoginScreen}/>
                <Route path="/DAOVoting" exact component={Voting}/>
                <Route path="/Staking" exact component={Staking}/>
                <Route path="/Trait_Swap" exact component={TraitSwap}/>
                <Route path="/Inventory" exact component={Inventory}/>
                <Route path="/Shop" exact component={Shop}/>
            </Switch>
        </AnimatePresence>
    );
}

export default AnimatedRoutes