import React from "react";
import "./spinner.css";

export default function ShopLoadingSpinner() {
  return (
    <div>
        <div className="purchasingOverlay2">
            <div className="spinner-container">
            <div className="loading-spinner"></div>
            </div>
        </div>
    </div>
  );
}