export const CheckItemQuantity = async(itemToCheck) => {
    
    let shopItems = await RetrieveShopItems()
    //console.log(shopItems)

    for (let i = 0; i < shopItems.length; i++) {
        if(itemToCheck == shopItems[i].value){
            if(shopItems[i].quantity > 0){
                return true
            }
        }
    }

    return false
}

const RetrieveShopItems = async () => {
    var shopConfig = "https://nudies.s3.us-west-1.amazonaws.com/Shop/Config.json"
    
    try {
        return fetch(shopConfig, {cache: "no-store"})
        .then((response) => response.json())
        .then((actualData) => {return actualData})
        .catch((err) => {
            console.log(err.message);
        });

    } catch (err) {
        console.log(err)
    }
}