import "./Voting.css"
import {ProposalsButton} from "./ProposalButton"
import React, { useState, useEffect, useRef } from 'react'; 

export const ProposalButtonArray = ({title, voteAnswers, allowedVotes, userVoted, updateResult}) => {
    
    return(
        <div className="buttonContainer">
            <ProposalsButton title={title} answer={voteAnswers.answer1} votingPower={allowedVotes} userVoted={userVoted} updateResult={updateResult}/>
            <ProposalsButton title={title} answer={voteAnswers.answer2} votingPower={allowedVotes} userVoted={userVoted} updateResult={updateResult}/>
            <ProposalsButton title={title} answer={voteAnswers.answer3} votingPower={allowedVotes} userVoted={userVoted} updateResult={updateResult}/>
            <ProposalsButton title={title} answer={voteAnswers.answer4} votingPower={allowedVotes} userVoted={userVoted} updateResult={updateResult}/>
            <ProposalsButton title={title} answer={voteAnswers.answer5} votingPower={allowedVotes} userVoted={userVoted} updateResult={updateResult}/>
        </div>
    )
}