import "./Inventory.css"
import React, { useState, useEffect, useRef } from 'react'; 
import { Button } from 'react-bootstrap'
import SignMessageInput from "../../Utils/Blockchain/SignMessage"
import {SendAPIPost} from "../../Utils/APIs/BackEndAPIs"
import Snackbar from "./../../Utils/Snackbar/Snackbar";

const SnackbarType = {
    success: "success",
    fail: "fail",
};
export const InventoryRemover = ({prop2, props, handler, chosenNudie}) =>{
    const snackbarRef = useRef(null);
    const [snackBarType, SetSnackBarType] = useState(SnackbarType.fail)
    const [snackBarMessage, SetSnackBarMessage] = useState("")

    const [showInventoryBox, setShowInventoryBox] = useState(prop2);

    const [showTrait5, setShowTrait5] = useState("none");
    const [showTrait6, setShowTrait6] = useState("none");
    const [showTrait7, setShowTrait7] = useState("none");
    const [showTrait8, setShowTrait8] = useState("none");
    const [showTrait9, setShowTrait9] = useState("none");

    const [layer1, setLayer1] = useState()
    const [layer2, setLayer2] = useState()
    const [layer3, setLayer3] = useState()
    const [layer4, setLayer4] = useState()
    const [layer5, setLayer5] = useState()
    const [layer6, setLayer6] = useState()
    const [layer7, setLayer7] = useState()
    const [layer8, setLayer8] = useState()
    const [layer9, setLayer9] = useState()

    const [value1, setValue1] = useState()
    const [value2, setValue2] = useState()
    const [value3, setValue3] = useState()
    const [value4, setValue4] = useState()
    const [value5, setValue5] = useState()
    const [value6, setValue6] = useState()
    const [value7, setValue7] = useState()
    const [value8, setValue8] = useState()
    const [value9, setValue9] = useState()

    const [display3, setDisplay3] = useState(false)
    const [display4, setDisplay4] = useState(false)
    const [display5, setDisplay5] = useState(false)
    const [display6, setDisplay6] = useState(false)
    const [display7, setDisplay7] = useState(false)
    const [display8, setDisplay8] = useState(false)
    const [display9, setDisplay9] = useState(false)

    var test = true;

    function hideRemoveInventoryBox(){
        handler("none")
        setShowInventoryBox("none")
        //console.log(props[0].traitType)
    }

  
    useEffect(async () => {
        setShowInventoryBox(prop2)
        if(props.length > 0){
            setLayer1(props[0].traitType)
            setLayer2(props[1].traitType)
            setLayer3(props[2].traitType)
            setLayer4(props[3].traitType)
            //setLayer5(props[4].traitType)
            setValue1(props[0].value)
            setValue2(props[1].value)
            setValue3(props[2].value)
            setValue4(props[3].value)
            //setValue5(props[4].value)

            if(props.length > 4){
                setLayer5(props[4].traitType)
                setValue5(props[4].value)  
                setShowTrait5("unset")              
            }

            if(props.length > 5){
                setLayer6(props[5].traitType)
                setValue6(props[5].value)  
                setShowTrait5("unset")              
                setShowTrait6("unset")              
            }

            if(props.length > 6){
                setLayer7(props[6].traitType)
                setValue7(props[6].value) 
                setShowTrait6("unset")               
                setShowTrait7("unset")                            
            }

            if(props.length > 7){
                setLayer8(props[7].traitType)
                setValue8(props[7].value) 
                setShowTrait6("unset")               
                setShowTrait7("unset")   
                setShowTrait8("unset")                        
            }

            if(props.length > 8){
                setLayer9(props[8].traitType)
                setValue9(props[8].value) 
                setShowTrait6("unset")               
                setShowTrait7("unset")   
                setShowTrait8("unset")  
                setShowTrait9("unset")                      
            }

            if(props.length < 5){
                //setShowTrait5("none")              
                setShowTrait6("none")              
                setShowTrait7("none")  
                setShowTrait8("none")  
                setLayer5("")
                setValue5("")             
            }

            if(props.length < 6){
                setShowTrait6("none")              
                setShowTrait7("none")  
                setShowTrait8("none")  
                setLayer6("")
                setValue6("")             
            }

            if(props.length < 7){
                //setShowTrait6("none")              
                setShowTrait7("none")  
                //setLayer6("")
                //setValue6("")  
                setLayer7("")
                setValue7("")              
            }

            if(props.length < 8){
                setShowTrait8("none")   
                setLayer8("")
                setValue8("")              
            }

            if(props.length < 9){
                setShowTrait9("none")   
                setLayer9("")
                setValue9("")              
            }

            if(props[2].traitType == "Eyes" || props[2].traitType == "Mouth" || props[2].traitType == "Skins" || props[2].traitType == "Background"){
                setDisplay3(true)
            }else{
                setDisplay3(false)
            }

            if(props[3].traitType == "Eyes" || props[3].traitType == "Mouth" || props[3].traitType == "Skins" || props[3].traitType == "Background"){
                setDisplay4(true)
            }else{
                setDisplay4(false)
            }

            if(props.length > 4){
                if(props[4].traitType == "Eyes" || props[4].traitType == "Mouth" || props[4].traitType == "Skins" || props[4].traitType == "Background"){
                    setDisplay5(true)
                }else{
                    setDisplay5(false)
                }
            }

            if(props.length > 5){
                if(props[5].traitType == "Eyes" || props[5].traitType == "Mouth" || props[5].traitType == "Skins" || props[5].traitType == "Background"){
                    setDisplay6(true)
                }else{
                    setDisplay6(false)
                }
            }

            if(props.length > 6){
                if(props[6].traitType == "Eyes" || props[6].traitType == "Mouth" || props[6].traitType == "Skins" || props[6].traitType == "Background"){
                    setDisplay7(true)
                }else{
                    setDisplay7(false)
                }         
            }

            if(props.length > 7){
                if(props[7].traitType == "Eyes" || props[7].traitType == "Mouth" || props[7].traitType == "Skins" || props[7].traitType == "Background"){
                    setDisplay8(true)
                }else{
                    setDisplay8(false)
                }         
            }

            if(props.length >= 8){
                if(props[8].traitType == "Eyes" || props[8].traitType == "Mouth" || props[8].traitType == "Skins" || props[8].traitType == "Background"){
                    setDisplay9(true)
                }else{
                    setDisplay9(false)
                }         
            }
        }

        //console.log(props)
    });

    async function returnCheckBoxes(){
        const allChecked = document.querySelectorAll('input[name=traitCheckboxes]:checked');
        //console.log(allChecked);
        //console.log(Array.from(allChecked).map(checkbox => checkbox.value));

        const RemoveInventoryItemsArray = Array.from(allChecked).map(checkbox => checkbox.value)
        const RemoveInventoryLayersArray = Array.from(allChecked).map(checkbox => checkbox.id)
        
        //NEED TO ADD WHAT CLOTHING LAYER ******************************************************
        const JSONString = JSON.stringify({ 
            Function: "RemoveTraits",
            NudieNFTNumber: chosenNudie,
            InventoryRemoveItemsArray: RemoveInventoryItemsArray,
            InventoryRemoveLayersArray: RemoveInventoryLayersArray        
        })

        const singedResponse = await SignMessageInput(JSONString)
        const responseStatus = await SendAPIPost(singedResponse) 
            
        if(singedResponse.signature.length < 1){

            SetSnackBarMessage("Singnature Declined")
            SetSnackBarType(SnackbarType.fail)
            snackbarRef.current.show()       

        }else{
            if(responseStatus == 200){
                SetSnackBarMessage("Request Sent")
                SetSnackBarType(SnackbarType.success)
                snackbarRef.current.show()  
            }
            if(responseStatus == 500){
                const secondResponseStatus = await SendAPIPost(singedResponse)  

                if(secondResponseStatus == 500){    
                    SetSnackBarMessage("Request Failed Please Try Again")
                    SetSnackBarType(SnackbarType.fail)
                    snackbarRef.current.show()  
                }
                if(secondResponseStatus == 200){
                    SetSnackBarMessage("Request Sent")
                    SetSnackBarType(SnackbarType.success)
                    snackbarRef.current.show()  
                }
            }  
        }
        //console.log(singedResponse)

    }

    return(
        <div className='infoPannelBK' style={{
            display:showInventoryBox
        }}> 
        <Snackbar
            ref={snackbarRef}
            message={snackBarMessage}
            type={snackBarType}
        />
        <div className='inventoryPannel'>
            <div className='inventoryPannelHeader'>
                <h1>Equipped Traits</h1>
                <button onClick={hideRemoveInventoryBox} className='closeInfo fa fa-times'>
                </button>
            </div>
            <div className='inventoryPannelSubSections'>
                <div className='layer'>
                    <h1>Layer</h1>  
                    <li>{layer1}</li>
                    <li>{layer2}</li>
                    <li>{layer3}</li>
                    <li>{layer4}</li>
                    <li>{layer5}</li>
                    <li>{layer6}</li>
                    <li>{layer7}</li>
                    <li>{layer8}</li>
                    <li>{layer9}</li>
                </div>
                <div className='trait'>
                    <h1>Trait</h1>
                    <li>{value1}</li>
                    <li>{value2}</li>
                    <li>{value3}</li>
                    <li>{value4}</li>
                    <li>{value5}</li>
                    <li>{value6}</li>
                    <li>{value7}</li>
                    <li>{value8}</li>
                    <li>{value9}</li>
                </div>
                <div className='remove'>
                    <h1>Remove</h1>
                    <li><input type="checkbox" id="trait1" className="removeBoxes" disabled/></li>
                    <li><input type="checkbox" id="trait2" className="removeBoxes" disabled/></li>
                    <li><input type="checkbox" name="traitCheckboxes" className="removeBoxes" value={value3} id={layer3} disabled={display3}/></li>
                    <li><input type="checkbox" name="traitCheckboxes" className="removeBoxes" value={value4} id={layer4} disabled={display4}/></li>
                    <li><input type="checkbox" name="traitCheckboxes" className="removeBoxes" value={value5} id={layer5} style={{display:showTrait5}} disabled={display5}/></li>
                    <li><input type="checkbox" name="traitCheckboxes" className="removeBoxes" value={value6} id={layer6} style={{display:showTrait6}} disabled={display6}/></li>
                    <li><input type="checkbox" name="traitCheckboxes" className="removeBoxes" value={value7} id={layer7} style={{display:showTrait7}} disabled={display7}/></li>
                    <li><input type="checkbox" name="traitCheckboxes" className="removeBoxes" value={value8} id={layer8} style={{display:showTrait8}} disabled={display8}/></li>
                    <li><input type="checkbox" name="traitCheckboxes" className="removeBoxes" value={value9} id={layer9} style={{display:showTrait9}} disabled={display9}/></li>
                </div>
            </div>
            <div className='inventoryPannelFooter'>
                <Button className='removeSelectedTraitButton' onClick={returnCheckBoxes}>Remove Selected</Button>
            </div>
        </div>
    </div>
    )
}


            // for (let i = 3; i < props.length; i++) {
            //     if(props[i].traitType == "Eyes" || props[i].traitType == "Mouth" || props[i].traitType == "Skins" || props[i].traitType == "Background"){
            //         if(i == 3){setDisplay3(true)}
            //         if(i == 4){setDisplay4(true)}
            //         if(i == 5){setDisplay5(true)}
            //         if(i == 6){setDisplay6(true)}
            //         if(i == 7){setDisplay7(true)}
            //     }
            // }