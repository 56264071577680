export const SendRequestAPIPost = async(Response) => {
    const URLAddition = await FormatAPICall(Response)

    const APIUrl = "https://17cytbb2t1.execute-api.us-west-1.amazonaws.com/default/ProccessSwapRequests" + URLAddition
    //console.log(APIUrl)

    const APIResponse = fetch(APIUrl)
                        .catch((err) => {
                            console.log(err.message);
                        });;
    //console.log(APIResponse)
    var resposeFromAPI = await APIResponse
    var statusCode = resposeFromAPI.status
    //console.log(resposeFromAPI)
    //console.log(statusCode)
    return statusCode
}

export const DeleteRequestAPIPost = async(Response) => {
    const URLAddition = await FormatAPICall(Response)

    const APIUrl = "https://6ajdmzordh.execute-api.us-west-1.amazonaws.com/default/DeleteSwapRequest" + URLAddition
    //console.log(APIUrl)

    const APIResponse = fetch(APIUrl)
                        .catch((err) => {
                            console.log(err.message);
                        });;
    //console.log(APIResponse)
    var resposeFromAPI = await APIResponse
    var statusCode = resposeFromAPI.status
    //console.log(resposeFromAPI)
    //console.log(statusCode)
    return statusCode
}

export const SendAPIPost = async(Response) => {
    const URLAddition = await FormatAPICall(Response)

    const APIUrl = "https://dr6s01nruc.execute-api.us-west-1.amazonaws.com/default/TraitSwapSQSPost" + URLAddition
    //console.log(APIUrl)

    const APIResponse = fetch(APIUrl)
                        .catch((err) => {
                            console.log(err.message);
                        });;
    //console.log(APIResponse)
    var resposeFromAPI = await APIResponse
    var statusCode = resposeFromAPI.status
    //console.log(resposeFromAPI)
    //console.log(statusCode)
    return statusCode
}

async function FormatAPICall(ResponseString){
    var APIString

    APIString = "?" + "message=" + ResponseString.message + "&" + "signature=" + ResponseString.signature + "&" + "address=" + ResponseString.address
    //console.log(APIString)

    return APIString
}
export const SendVoteAPIPost = async(Response) => {
    const URLAddition = await FormatAPICall(Response)

    const APIUrl = "https://1x85lr016j.execute-api.us-west-1.amazonaws.com/default/VotingLogger" + URLAddition
    //console.log(APIUrl)

    const APIResponse = fetch(APIUrl)
                        .catch((err) => {
                            console.log(err.message);
                        });;
    var resposeFromAPI = await APIResponse
    var statusCode = resposeFromAPI.status
    //console.log(resposeFromAPI)
    //console.log(statusCode)
    return statusCode
}

export const SendShopAPIPost = async(Response) => {
    const URLAddition = await FormatAPICall(Response)

    const APIUrl = "https://dbqbotfuh6.execute-api.us-west-1.amazonaws.com/default/ShopQuantityFilter" + URLAddition
    //console.log(APIUrl)

    const APIResponse = fetch(APIUrl)
                        .catch((err) => {
                            console.log(err.message);
                        });;
    //console.log(APIResponse)
    var resposeFromAPI = await APIResponse
    var statusCode = resposeFromAPI.status
    //console.log(resposeFromAPI)
    //console.log(statusCode)
    return statusCode
}

export const SendInitClosetAPIPost = async(Response) => {
    const URLAddition = await FormatAPICall(Response)

    const APIUrl = "https://6907lsuioc.execute-api.us-west-1.amazonaws.com/default/InitInventory" + URLAddition
    //console.log(APIUrl)

    const APIResponse = fetch(APIUrl)
                        .catch((err) => {
                            console.log(err.message);
                        });;
    //console.log(APIResponse)
    var resposeFromAPI = await APIResponse
    var statusCode = resposeFromAPI.status
    //console.log(resposeFromAPI)
    //console.log(statusCode)
    return statusCode
}

