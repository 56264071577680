import "./../TraitSwap.css"
import React, { useState, useEffect, useRef } from 'react'; 
import SignMessageInput from "./../../../Utils/Blockchain/SignMessage"
import {SendAPIPost, DeleteRequestAPIPost} from "./../../../Utils/APIs/BackEndAPIs"
import { getCurrentWalletConnected } from "./../../../Utils/Wallet/Interact";
import cancelIcon from "./../../../Images/cancel-icon.png"
import confirmIcon from "./../../../Images/confirm-icon.png"
import Snackbar from "./../../../Utils/Snackbar/Snackbar";

const SnackbarType = {
    success: "success",
    fail: "fail",
};


//export const Requests = ({RequestID, RequestFromNumber, RequestTradeItems, OwnedNumber, OwnedTrait_type, OwnedValue}) =>{
export const Requests = ({GUUID, RequestFromNumber, RequestTradeItems, RequestTradeTrait_type, RequestTradeValue, OwnedNumber, OwnedTrait_type, OwnedValue}) =>{
    const snackbarRef = useRef(null);
    const [snackBarType, SetSnackBarType] = useState(SnackbarType.fail)
    const [snackBarMessage, SetSnackBarMessage] = useState("")

    var wallet = ""
    const { toChecksumAddress } = require('ethereum-checksum-address')
    var itemListString = "N/A"
    var count = 1

    for (let i = 0; i < RequestTradeItems.length; i++) {

        if(i % 2 == 0){
            if(i == 0){
                itemListString = "1) " + RequestTradeItems[i] + " : " + RequestTradeItems[i + 1];
            }else{
                itemListString = itemListString + " + " + (count) + ") " + RequestTradeItems[i] + " : " + RequestTradeItems[i + 1]; 
            }
            count++
        }
        //console.log(itemListString)
    }

    useEffect(async () => {
        wallet = await (await getCurrentWalletConnected()).address
    }, []);

    async function acceptRequest(){
        var singedResponse = ""
        const JSONString = JSON.stringify({ 
                            Function: "AcceptRequest",
                            GUUID: GUUID})
                            //RequestID: RequestID
                            // RequestFromNumber: RequestFromNumber,
                            // RequestTradeItems: RequestTradeItems,
                            // OwnedNumber: OwnedNumber,
                            // OwnedTrait_type: OwnedTrait_type,
                            // OwnedValue:OwnedValue

        singedResponse = await SignMessageInput(JSONString)    
        //console.log(singedResponse)

        if(singedResponse.signature.length < 1){
            SetSnackBarMessage("Singnature Declined")
            SetSnackBarType(SnackbarType.fail)
            snackbarRef.current.show()     

        }else{

            const responseStatus = await SendAPIPost(singedResponse) 
            
            if(responseStatus == 200){
                SetSnackBarMessage("Request Sent")
                SetSnackBarType(SnackbarType.success)
                snackbarRef.current.show()  
            }
            if(responseStatus == 500){
                const secondResponseStatus = await SendAPIPost(singedResponse)
  

                if(secondResponseStatus == 500){    
                    SetSnackBarMessage("Request Failed Please Try Again")
                    SetSnackBarType(SnackbarType.fail)
                    snackbarRef.current.show()  
                }
                if(secondResponseStatus == 200){
                    SetSnackBarMessage("Request Sent")
                    SetSnackBarType(SnackbarType.success)
                    snackbarRef.current.show()  
                }
            }                    
        }
    }

    async function declineRequest(){
        var singedResponse = ""
        const JSONString = JSON.stringify({ 
                            Function: "DeclineRequest",
                            GUUID: GUUID
                        })

        singedResponse = await SignMessageInput(JSONString)           
        //console.log(singedResponse)

        if(singedResponse.signature.length < 1){
            SetSnackBarMessage("Singnature Declined")
            SetSnackBarType(SnackbarType.fail)
            snackbarRef.current.show()     

        }else{

            const responseStatus = await DeleteRequestAPIPost(singedResponse)  
            
            if(responseStatus == 200){
                SetSnackBarMessage("Request Sent")
                SetSnackBarType(SnackbarType.success)
                snackbarRef.current.show()  
            }
            if(responseStatus == 500){
                const secondResponseStatus = await DeleteRequestAPIPost(singedResponse) 
  

                if(secondResponseStatus == 500){    
                    SetSnackBarMessage("Request Failed Please Try Again")
                    SetSnackBarType(SnackbarType.fail)
                    snackbarRef.current.show()  
                }
                if(secondResponseStatus == 200){
                    SetSnackBarMessage("Request Sent")
                    SetSnackBarType(SnackbarType.success)
                    snackbarRef.current.show()  
                }
            }                    
        }
    }

    return(
        <div>
            <Snackbar
                ref={snackbarRef}
                message={snackBarMessage}
                type={snackBarType}
            />
            <div className="requestBox">
                <div className="requestsTitles">
                    <p>Request From Number :</p>
                    <p>Requested Trade Item :</p>
                    <p>Additional Trade Items :</p>
                    <p>Your Nudie Number :</p>
                    <p>Your Trade Items :</p>
                </div>
                <div className="requestResults">
                    <p>{RequestFromNumber}</p>
                    <p>{RequestTradeTrait_type} : {RequestTradeValue}</p>
                    <p>{itemListString}</p>
                    <p>{OwnedNumber}</p>
                    <p>{OwnedTrait_type} : {OwnedValue}</p>
                </div>
                <div className="requestAnswers">
                    <div className="request-answer-img-box">
                        <img src={cancelIcon} onClick={declineRequest}/>
                    </div>
                    <div className="request-answer-img-box">
                        <img src={confirmIcon} onClick={acceptRequest}/>
                    </div>
                </div>
            </div>
            <div className="requestBoxMobile">
                <div className="requestsTitles">
                    <h4>Request From Number:</h4>
                    <p>{RequestFromNumber}</p>

                    <h4>Requested Trade Item:</h4>
                    <p>{RequestTradeTrait_type} : {RequestTradeValue}</p>

                    <h4>Additional Trade Items:</h4>
                    <p>{itemListString}</p>

                    <h4>Your Nudie Number:</h4>
                    <p>{OwnedNumber}</p>

                    <h4>Your Trade Items:</h4>
                    <p>{OwnedTrait_type} : {OwnedValue}</p>
                </div>
                <div className="requestAnswersMobile">
                    <div className="request-answer-img-box">
                        <img src={cancelIcon} onClick={declineRequest}/>
                    </div>
                    <div className="request-answer-img-box">
                        <img src={confirmIcon} onClick={acceptRequest}/>
                    </div>
                </div>
            </div>
        </div>
    )
}