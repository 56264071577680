import React, { useState, useEffect, useRef } from 'react'; 
import {filter, utils} from "ethers";
import Card from "../../Utils/Cards/Card"
import CardsStaked from '../../Utils/Cards/CardStaked';
import { Button } from 'react-bootstrap'
import "./Staking.css"
import { getCurrentWalletConnected, walletConnectWallet, clearWalletConnectWallet } from "./../../Utils/Wallet/Interact";
import {Redirect, Route} from "react-router-dom";
import { getConnectedStakingContract, getConnectedERC721Contract, getConnectedERC20Contract } from '../../Utils/Contracts/ContractConnections';
//import coin from "./../../Images/coin.png"
import coin from "./../../Images/cashnipcoin.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Snackbar from "./../../Utils/Snackbar/Snackbar";

require('dotenv').config()
AOS.init();

const APIKEY1 = process.env.REACT_APP_KEY1;

const SnackbarType = {
    success: "success",
    fail: "fail",
};


const Staking = () => {
    const snackbarRef = useRef(null);
    const [snackBarType, SetSnackBarType] = useState(SnackbarType.fail)
    const [snackBarMessage, SetSnackBarMessage] = useState("")

    const [chosenNudie, setChosenNudie] = useState()
    const [showChosenNudie, setShowChosenNudie] = useState("none")

    const [chosenStakedImage, setChosenStakedImage] = useState([])
    const [chosenImage, setChosenImage] = useState([])
    const [chosenNudieImage, setChosenNudieImage] = useState("")

    const [walletAmount, setWalletAmount] = useState(0)
    const [tokensToClaim, setTokensToClaim] = useState(0)

    const [wallet, setWallet] = useState()//getCurrentWalletConnected());
    const [displayWallet, setDisplayWallet] = useState("");

    const [logBackIn, setLogBackIn] = useState(false);
    const [showInfo, setShowInfo] = useState("none");
    const [showAuth, setShowAuth] = useState("none");
    const [showUnstakeInfo, setUnstakeInfo] = useState("none");

    const [openseaAPIFailure, setOpenseaAPIFaliure] = useState(false)

    const imagesArray = []
    const stakedImagesArray = []
    const stakingContractAddress = "0x8Dd465DF3D1d4E0a4ae978FA7fb30Eb558AB8714"
    const { toChecksumAddress } = require('ethereum-checksum-address')

    var wallet2 = ""
    var nudieTradedArray = []

    useEffect(() => {
        if(chosenNudie >= 1){
            setShowChosenNudie("block")
            setChosenNudieImage("https://nudiecommunity.mypinata.cloud/ipfs/QmXAXsvjKpVE8Ar2EhCSm5MvFMAWe6pnXNzkAra3ekDsnD/" + chosenNudie + ".png")
        }
    });

    const fetchOwnCollection = async (walletAddress) => {
        const WALLET_ADDRESS = walletAddress;

        const options = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'X-API-KEY': APIKEY1,
        },
        };
    
        const collectionResponse = await fetch(
        `https://api.opensea.io/api/v1/collections?asset_owner=${WALLET_ADDRESS}`,
        options,
        ).then(response => response.json())
        .catch((err) => {
            //console.log(err.message);
        });
 
        //console.log(collectionResponse)

        if(collectionResponse.length > 0){
            //console.log("run")

            const collection = collectionResponse.map(item => ({
                details: item.description,
                slug: item.slug,
                name: item.name,
                contractAddress: "0x89Bd11D1fBa7906464fa11f3086fE8ba1B5368A1",
                owned: [],
            }));

        
            for (const iterator of collection) {
            const assetsResponse = await fetch(
                `https://api.opensea.io/api/v1/assets?owner=${WALLET_ADDRESS}&asset_contract_address=0x89Bd11D1fBa7906464fa11f3086fE8ba1B5368A1&limit=100&include_orders=false`,
                options,
            ).then(response => response.json())
            .catch((err) => {
                console.log(err.message);
            });
        
            iterator.owned = assetsResponse.assets
                .map(item => ({
                name: item.name,
                img: item.image_url,
                id: item.token_id,
                }))
                .filter(item => item.name && item.img);
            }
            var ownerTokenIDs = []

            for (let p = 0; p < collection[0].owned.length; p++) {
                ownerTokenIDs[p] = collection[0].owned[p].id;
            }
            //console.log(ownerTokenIDs)
        }
        return ownerTokenIDs;
    };


    useEffect(() => {
        const interval = setInterval(() => {
            getWalletAmount()
            calculateStakingIncome()
            loadStakedNudies()
            .catch((err) => {
                console.log(err.message);
            });
          }, 2000);
        return () => clearInterval(interval);
    }, [stakedImagesArray, imagesArray])

    useEffect(async () => {
        wallet2 = await (await getCurrentWalletConnected()).address
        //if(!wallet2){wallet2 = await (await walletConnectWallet()).address}

        try{
            // fetchOwnCollection(wallet2)
            // .then((response) => RenderNudies(response))
            // .catch((err) => {
            //     console.log(err.message);
            //     setOpenseaAPIFaliure(true)
            //     SetSnackBarMessage("Opensea API request timeout, please reload page. Fallback API loaded")
            //     SetSnackBarType(SnackbarType.fail)
            //     try {
            //         snackbarRef.current.show()        
            //     } catch (error) {
            //         console.log(error)
            //     }
                fetch("https://api.etherscan.io/api?module=account&action=tokennfttx&contractaddress=0x89Bd11D1fBa7906464fa11f3086fE8ba1B5368A1&address=" + wallet2 + "&offset=100&startblock=0&endblock=27025780&sort=asc&apikey=T7U7YX32H4KYIBBJ1NT6BVJWZHWQQ7NY4B")
                .then((response) => response.json())
                .then((actualData) => loadUserNudies(actualData))
                .catch((err) => {
                    console.log(err.message);
                });
            //});
        }catch(err){
            console.log(err)
        }
        setDisplayWallet(wallet2)

        // fetch("https://api.etherscan.io/api?module=account&action=tokennfttx&contractaddress=0x89Bd11D1fBa7906464fa11f3086fE8ba1B5368A1&address=" + wallet2 + "&page=1&offset=100&startblock=0&endblock=27025780&sort=asc&apikey=T7U7YX32H4KYIBBJ1NT6BVJWZHWQQ7NY4B")
        // //fetch("https://api.etherscan.io/api?module=account&action=tokennfttx&contractaddress=0x89Bd11D1fBa7906464fa11f3086fE8ba1B5368A1&address=0xB3fd3b938E203C9B614743f48Ea6824A266D1256&page=1&offset=100&startblock=0&endblock=27025780&sort=asc&apikey=T7U7YX32H4KYIBBJ1NT6BVJWZHWQQ7NY4B")
        // //fetch("https://api.etherscan.io/api?module=account&action=tokennfttx&contractaddress=0x89Bd11D1fBa7906464fa11f3086fE8ba1B5368A1&address=0xd3c5b9af0f6a459746e15ea4d744fe5fbe6b75c2&page=1&offset=100&startblock=0&endblock=27025780&sort=asc&apikey=T7U7YX32H4KYIBBJ1NT6BVJWZHWQQ7NY4B")
        // .then((response) => response.json())
        //  .then((actualData) => loadUserNudies(actualData))
        //  .catch((err) => {
        //   console.log(err.message);
        //  });

        loadStakedNudies()
        .catch((err) => {
            console.log(err.message);
        });
    }, []);

    // async function nudieTradedArray(){
    //     var nudieTradedArray = []
    //     for (let j = 0; j < actualData.result.length; j++) {
    //         if(actualData.result[j].from == loadWallet){
    //             nudieTradedArray.push(actualData.result[j].tokenID)
    //         }
    //     }  
    //     return nudieTradedArray
    // }

    async function loadUserNudies(actualData){
        let match = false;
        let matchFromStaker = false;

        //const loadWallet = (await wallet).address
        const loadWallet = wallet2;

        var nudieMatchArray = []
        //console.log(actualData.result)

        try{
            for (let j = 0; j < actualData.result.length; j++) {
                //console.log(actualData.result[j].from + "  " + loadWallet)
                if(actualData.result[j].from == loadWallet || toChecksumAddress(actualData.result[j].from.toString()) == loadWallet){
                  //console.log("from:")
                    //console.log(actualData.result[j])
                    for (let o = j; o < actualData.result.length; o++) {

                        if(actualData.result[o].from == "0x8dd465df3d1d4e0a4ae978fa7fb30eb558ab8714" || actualData.result[o].to == "0x8dd465df3d1d4e0a4ae978fa7fb30eb558ab8714"){
                            if(actualData.result[o].tokenID == actualData.result[j].tokenID){
                                //console.log(actualData.result[o].to + actualData.result[o].from)
                                matchFromStaker = true;                                
                            }
                        }

                        if(actualData.result[o].to == loadWallet || toChecksumAddress(actualData.result[o].to.toString()) == loadWallet){
                            if(actualData.result[o].tokenID == actualData.result[j].tokenID){
                                //console.log(actualData.result[o].to + actualData.result[o].from)
                                matchFromStaker = true;                                
                            }                        
                        }
                    }
                    if(matchFromStaker == false){
                        nudieTradedArray.push(actualData.result[j].tokenID)
                    }
                    matchFromStaker = false
                } 
            }   
            //console.log(nudieTradedArray)


            for(var i = 0; i < actualData.result.length; i++){

                
                //console.log(actualData.result[i].to)
                match = false;
                    //if(actualData.result[i].to == loadWallet){
                    //}
                    for (let l = 0; l < nudieMatchArray.length; l++) {
                        if(nudieMatchArray[l] == actualData.result[i].tokenID) {
                            match = true;
                        }  
                    }
                    // match = false;
                    // for (let j = 0; j < actualData.result.length; j++) {
                    //     if(actualData.result[j].to != (await wallet).address){
                    //         nudieMatch.push(actualData.result[j].tokenID)
                    //         console.log(nudieMatch)
                    //     }
                    // }
                    // for (let k = 0; k < nudieMatch.length; k++) {
                    //     if(nudieMatch[k] == actualData.result[i].tokenID){
                    //         match = true
                    //     } 
                    // }
                    // if(match == false){
                    //     imagesArray.push(
                    //         {
                    //             "ID": actualData.result[i].tokenID,
                    //             "Image": "https://nudiecommunity.mypinata.cloud/ipfs/QmXAXsvjKpVE8Ar2EhCSm5MvFMAWe6pnXNzkAra3ekDsnD/" + actualData.result[i].tokenID + ".png",
                    //             "Colour": "",                        
                    //         }
                    //     )  
                    //     setChosenNudie(actualData.result[i].tokenID)   
                    // }                    
                    if(match == false){

                        if (nudieTradedArray.indexOf(actualData.result[i].tokenID) == -1)
                            {
                                imagesArray.push(
                                    {
                                        "ID": actualData.result[i].tokenID,
                                        "Image": "https://nudies.s3.us-west-1.amazonaws.com/Assets/img/" + actualData.result[i].tokenID + ".png",
                                        "Colour": "",                        
                                    }
                                )  
                            }

                        // imagesArray.push(
                        //     {
                        //         "ID": actualData.result[i].tokenID,
                        //         "Image": "https://nudiecommunity.mypinata.cloud/ipfs/QmXAXsvjKpVE8Ar2EhCSm5MvFMAWe6pnXNzkAra3ekDsnD/" + actualData.result[i].tokenID + ".png",
                        //         "Colour": "",                        
                        //     }
                        // )  
                    }
                    nudieMatchArray.push(actualData.result[i].tokenID)
                    //     setChosenNudie(actualData.result[i].tokenID)   
                    // }
                //}
            }
        }catch(err){
            console.log(err)
        }
        //console.log(imagesArray)
        setChosenImage(imagesArray)
    }

    async function loadStakedNudies(){
        try{
            const connectedStakingContract = await getConnectedStakingContract()
            if(!connectedStakingContract) return;
                const stakedNudies = connectedStakingContract.returnStakedNudies()
                .then((response) => RenderStakedNudies(response)) 
                .catch((err) => {
                    console.log(err.message);
                });
                //console.log("changed")             
        }catch (err){
            console.log(err)
        }
    }

    async function RenderNudies(response){
        if(response.length != null){
            for(var i = 0; i < response.length; i++){
                imagesArray.push(
                    {
                        "ID": response[i],
                        "Image": "https://nudies.s3.us-west-1.amazonaws.com/Assets/img/" + response[i] + ".png",
                        "Colour": "",                        
                    }
                )         
            }      
            setChosenImage(imagesArray)            
        }

    }

    async function RenderStakedNudies(response){
        //console.log(response)
            for(var i = 0; i < response.length; i++){
                stakedImagesArray.push(
                    {
                        "ID": parseInt(response[i].toHexString(16), 16),
                        "Image": "https://nudies.s3.us-west-1.amazonaws.com/Assets/img/" + parseInt(response[i].toHexString(16), 16) + ".png",
                        "Colour": "",                        
                    }
                )         
            }  
    
        //console.log("staked image array ")
        //console.log(stakedImagesArray)
        setChosenStakedImage(stakedImagesArray)
    }

    async function StakeNudie(){
        try{ 
            const connectedStakingContract = await getConnectedStakingContract()
            const connectedERC721Contract = await getConnectedERC721Contract()
            wallet2 = await (await getCurrentWalletConnected()).address
            const connected = await connectedERC721Contract.isApprovedForAll(wallet2, stakingContractAddress)
            if(!connectedStakingContract) return;
                if(!connected){
                    setShowAuth("block")
                }else{
                    try {
                        await connectedStakingContract.stake(chosenNudie)
                        SetSnackBarMessage("Staking Requested")
                        SetSnackBarType(SnackbarType.success)
                        snackbarRef.current.show()  
                    } catch (error) {
                        SetSnackBarMessage("Error Staking")
                        SetSnackBarType(SnackbarType.fail)
                        snackbarRef.current.show() 
                        console.log(error) 
                    }
                }
        } catch (err){
        console.log(err.message);
      }

            // var nudiess = []

            // for(var i = 1; i < 10000; i++){
            //     var testt = await connectedERC721Contract.ownerOf(i)
            //     //console.log(testt + " " + " " + i)

            //     if(testt == "0x8Dd465DF3D1d4E0a4ae978FA7fb30Eb558AB8714"){
            //         console.log(i)
            //         nudiess.push(i)
            //     }
            // }
            // console.log(nudiess)
    }

    async function StakeAllNudies(){
        try{ 
            wallet2 = await (await getCurrentWalletConnected()).address
            if(!wallet2){wallet2 = await (await walletConnectWallet()).address}
            setDisplayWallet(wallet2)

            let nudiesToStake = await getAllNudiesToStake()
            stakeAllRequest(nudiesToStake)
            
            // fetchOwnCollection(wallet2)
            // .then((response) => stakeAllRequest(response))
            // .catch((err) => {
            //     console.log(err.message);
            //     setOpenseaAPIFaliure(true)
            //     SetSnackBarMessage("Opensea API request timeout, please  try again")
            //     SetSnackBarType(SnackbarType.fail)
            //     snackbarRef.current.show()  
            // });
            // fetch("https://api.etherscan.io/api?module=account&action=tokennfttx&contractaddress=0x89Bd11D1fBa7906464fa11f3086fE8ba1B5368A1&address=" + wallet2 + "&page=1&offset=100&startblock=0&endblock=27025780&sort=asc&apikey=T7U7YX32H4KYIBBJ1NT6BVJWZHWQQ7NY4B")
            //  .then((response) => response.json())
            //  .then((actualData) => stakeArray(actualData))
            //  .catch((err) => {
            //   console.log(err.message);
            //  });
        } catch (err){               
        console.log(err.message);
      }
    }

    async function getAllNudiesToStake(){
        console.log(chosenImage)
        let newlist = []
        let removeList = []
        for (let i = 0; i < chosenImage.length; i++) {
            newlist.push(chosenImage[i].ID)
        }

        for (let i = 0; i < chosenStakedImage.length; i++) {
            removeList.push(chosenStakedImage[i].ID.toString())
        }

        console.log(newlist)
        console.log(removeList)

        newlist = newlist.filter( ( el ) => !removeList.includes( el ) );
        console.log(newlist)

        return newlist
    }

    async function stakeAllRequest(response){
        try{ 
            //console.log(response)
            const connectedStakingContract = await getConnectedStakingContract()
            const connectedERC721Contract = await getConnectedERC721Contract()
            wallet2 = await (await getCurrentWalletConnected()).address
            //console.log(response)
            const connected = await connectedERC721Contract.isApprovedForAll(wallet2, stakingContractAddress)

            if(!connectedStakingContract) return;
                if(!connected){
                    setShowAuth("block")
                }else{
                    if(response.length > 0){              
                        try {
                            const stakedNudies = await connectedStakingContract.stakeMultiple(response)
                            SetSnackBarMessage("Stake All Requested")
                            SetSnackBarType(SnackbarType.success)
                            snackbarRef.current.show()  
                        } catch (error) {
                            SetSnackBarMessage("Error Staking")
                            SetSnackBarType(SnackbarType.fail)
                            snackbarRef.current.show()  
                        }
                    }else{
                        SetSnackBarMessage("Error Staking All")
                        SetSnackBarType(SnackbarType.fail)
                        snackbarRef.current.show()  
                    }
            }
            } 
        catch (err){
            console.log(err);
        }
    }

    async function stakeArray(actualData){
        let match = false;
        var stakeArray = []
        const loadWallet = (await wallet).address
        var nudieMatchArray = []
        var nudieTradedArray = []

        try{
            for (let j = 0; j < actualData.result.length; j++) {
                if(actualData.result[j].from == loadWallet){
                    //console.log(actualData.result[j])
                    nudieTradedArray.push(actualData.result[j].tokenID)
                } 
            }   
            for(var i = 0; i < actualData.result.length; i++){
                //console.log(actualData.result[i].to)
                match = false;
                if(actualData.result[i].to == loadWallet){
                    for (let l = 0; l < nudieMatchArray.length; l++) {
                        if(nudieMatchArray[l] == actualData.result[i].tokenID) {
                            match = true;
                        }  
                    }
                    // match = false;
                    // for (let j = 0; j < actualData.result.length; j++) {
                    //     if(actualData.result[j].to != (await wallet).address){
                    //         nudieMatch.push(actualData.result[j].tokenID)
                    //         console.log(nudieMatch)
                    //     }
                    // }
                    // for (let k = 0; k < nudieMatch.length; k++) {
                    //     if(nudieMatch[k] == actualData.result[i].tokenID){
                    //         match = true
                    //     } 
                    // }
                    // if(match == false){
                    //     imagesArray.push(
                    //         {
                    //             "ID": actualData.result[i].tokenID,
                    //             "Image": "https://nudiecommunity.mypinata.cloud/ipfs/QmXAXsvjKpVE8Ar2EhCSm5MvFMAWe6pnXNzkAra3ekDsnD/" + actualData.result[i].tokenID + ".png",
                    //             "Colour": "",                        
                    //         }
                    //     )  
                    //     setChosenNudie(actualData.result[i].tokenID)   
                    // }                    
                    if(match == false){
                        if (nudieTradedArray.indexOf(actualData.result[i].tokenID) == -1)
                        {
                            //console.log(actualData.result[i].tokenID)
                            if(actualData.result[i].tokenID != null){
                                stakeArray[i] = actualData.result[i].tokenID
                            }
                        }
                    }
                    nudieMatchArray.push(actualData.result[i].tokenID)
                    //     setChosenNudie(actualData.result[i].tokenID)   
                    // }
                }
            }
            //console.log(stakeArray)
        }catch(err){
            console.log(err)
        }


        // try{
        //     for(var i = 0; i < actualData.result.length; i++){
        //         if(actualData.result[i].to == (await wallet).address){
        //             for (let j = 0; j < actualData.result.length; j++) {
        //                 if(actualData.result[j].from == (await wallet).address){
        //                     match = true;
        //                 }
        //             }
        //             if(match == false){
        //                 stakeArray[i] = actualData.result[i].tokenID
        //             }      
        //         }
        //         match = false
        //     }
        // }catch{
        //     console.log("Images Cant Load")
        // }
        try{ 
            const connectedStakingContract = await getConnectedStakingContract()
            wallet2 = await (await getCurrentWalletConnected()).address
            //console.log(stakeArray)

            if(!connectedStakingContract) return;
                if(stakeArray.length > 0){              
                try {
                    const stakedNudies = await connectedStakingContract.stakeMultiple(stakeArray)
                    SetSnackBarMessage("Stake All Requested")
                    SetSnackBarType(SnackbarType.success)
                    snackbarRef.current.show()  
                } catch (error) {
                    SetSnackBarMessage("Error Staking")
                    SetSnackBarType(SnackbarType.fail)
                    snackbarRef.current.show()  
                }
            }else{
                SetSnackBarMessage("Error Staking All")
                SetSnackBarType(SnackbarType.fail)
                snackbarRef.current.show()  
            }
            } 
        catch (err){
            console.log(err);
        }
    }


    async function AuthorizeContract(){
        const connectedERC721Contract = await getConnectedERC721Contract()
        connectedERC721Contract.setApprovalForAll(stakingContractAddress, true)
    }

    async function UnStakeNudie(){
        try{ 
            const connectedStakingContract = await getConnectedStakingContract()
            const connectedERC721Contract = await getConnectedERC721Contract()
            wallet2 = await (await getCurrentWalletConnected()).address
            const connected = await connectedERC721Contract.isApprovedForAll(wallet2, stakingContractAddress)

            if(!connectedStakingContract) return;
                try {
                    await connectedStakingContract.unstake(chosenNudie)
                    SetSnackBarMessage("Unstaking Requested")
                    SetSnackBarType(SnackbarType.success)
                    snackbarRef.current.show()  
                    showUnstakeInfoBox()
                } catch (error) {
                    SetSnackBarMessage("Error Unstaking")
                    SetSnackBarType(SnackbarType.fail)
                    snackbarRef.current.show()  
                }
            } 
        catch (err){
            console.log(err);
        }
    }

    async function UnStakeAllNudies(){
        try{ 
            const connectedStakingContract = await getConnectedStakingContract()
            wallet2 = await (await getCurrentWalletConnected()).address
            if(!connectedStakingContract) return;
                try {
                    const stakedNudies = connectedStakingContract.returnStakedNudies()
                    .then((response) => UnstakeArray(response)) 
                    .catch((err) => {
                        console.log(err.message);
                    });  
                } catch (error) {
                    SetSnackBarMessage("Error Unstaking")
                    SetSnackBarType(SnackbarType.fail)
                    snackbarRef.current.show()  
                }
            } 
        catch (err){
            console.log(err);
        }
    }

    async function UnstakeArray(response){
        try{ 
            const connectedStakingContract = await getConnectedStakingContract()
            wallet2 = await (await getCurrentWalletConnected()).address

            var unstakeArray = []

            for (let i = 0; i < response.length; i++) {
                unstakeArray[i] = parseInt(response[i].toHexString(16), 16);
            }

            //console.log(unstakeArray)

            if(!connectedStakingContract) return;
            if(unstakeArray.length > 0){
                try {
                    const stakedNudies = await connectedStakingContract.unstakeMultiple(unstakeArray)
                    SetSnackBarMessage("Unstake All Requested")
                    SetSnackBarType(SnackbarType.success)
                    snackbarRef.current.show()  
                    showUnstakeInfoBox();
                } catch (error) {
                    SetSnackBarMessage("Error Unstaking All")
                    SetSnackBarType(SnackbarType.fail)
                    snackbarRef.current.show()  
                }                
            }else{
                SetSnackBarMessage("Error Unstaking All")
                SetSnackBarType(SnackbarType.fail)
                snackbarRef.current.show()  
            }

            } 
        catch (err){
            console.log(err);
        }
    }

    async function calculateStakingIncome(){
        try{  
            const connectedStakingContract = await getConnectedStakingContract()
            wallet2 = await (await getCurrentWalletConnected()).address
     
            if(!connectedStakingContract) return;
            {
                try {
                    const claimTotalValue = await connectedStakingContract.calculateRewards(wallet2)
                    setTokensToClaim(parseInt(claimTotalValue.toHexString(16), 16))                    
                } catch (error) {                 
                }
            }
          } catch (err){
            console.log(err);
          }
    }

    async function ClaimStakingIncome(){
        const connectedERC20Contract = await getConnectedERC20Contract()
        wallet2 = await (await getCurrentWalletConnected()).address

        try{      
            if(!connectedERC20Contract) return;
            {
                const claimTotalValue = await connectedERC20Contract.claimReward()
                SetSnackBarMessage("Tokens Requested")
                SetSnackBarType(SnackbarType.success)
                snackbarRef.current.show()  
            }
          } catch (err){
            SetSnackBarMessage("Claiming error")
            SetSnackBarType(SnackbarType.fail)
            snackbarRef.current.show()  
            console.log(err);
          }
    }

    async function getWalletAmount(){
        const connectedERC20Contract = await getConnectedERC20Contract()
        wallet2 = await (await getCurrentWalletConnected()).address
        try{      
            if(!connectedERC20Contract) return;
            {
                try {
                    const walletERC20Total = await connectedERC20Contract.balanceOf(wallet2)
                    const number = (parseInt(walletERC20Total.toHexString(16), 16) / 1000000000000000000)
                    setWalletAmount(number);                    
                } catch (error) {   
                }
            }
          } catch (err){
            console.log(err);
          }
    }

    async function LogBackIn(){
        setLogBackIn(true)
        wallet2 = null;
        //await clearWalletConnectWallet();
    }

    function hideInfoBox(){
        setShowInfo("none")
    }

    function showInfoBox(){
        setShowInfo("block")
    }

    function hideAuthBox(){
        setShowAuth("none")
    }

    function showAuthBox(){
        setShowAuth("block")
    }

    function hideUnstakeInfoBox(){
        setUnstakeInfo("none")
    }

    function showUnstakeInfoBox(){
        setUnstakeInfo("block")
    }

      return(
        <div className='stakingPage'>
                <Snackbar
                    ref={snackbarRef}
                    message={snackBarMessage}
                    type={snackBarType}
                />
            <div className='infoPannelBK' style={{
                    display:showInfo
                }}>      
                <div className='infoPannel'>
                    <div className='infoPannelHeader'>
                        <h1>Staking Reward Guide</h1>
                        <button onClick={hideInfoBox} className='closeInfo fa fa-times'>
                        </button>
                    </div>
                    <div className='infoPannelSection'>
                        <div className='daysStaked'>7 Days Staked</div>
                        <div className='coinsEarned'>350 <img src={coin}/></div>
                    </div>
                    <div className='infoPannelSection'>
                        <div className='daysStaked'>15 Days Staked</div>
                        <div className='coinsEarned'>1000 <img src={coin}/></div>
                    </div>
                    <div className='infoPannelSection'>
                        <div className='daysStaked'>30 Days Staked</div>
                        <div className='coinsEarned'>3000 <img src={coin}/></div>
                    </div>
                    <div className='infoPannelSection'>
                        <div className='daysStaked'>30+ Days, Every 7th day</div>
                        <div className='coinsEarned'>+700 <img src={coin}/></div>
                    </div>
                    <div className='infoPannelFooter'>
                        <div className='stakeInfo'>These coin values are per Nudie staked. 
                        Claiming or unstaking Nudies before claiming, will reset that tokens staked timer.</div>
                    </div>
                </div>
            </div>

            <div className='infoPannelBK' style={{
                    display: showUnstakeInfo
                }}>      
                <div className='infoPannel'>
                        <div className='infoPannelHeader'>
                            <h1>Unstaking Information</h1>
                            <button onClick={hideUnstakeInfoBox} className='closeInfo fa fa-times'>
                            </button>
                        </div><br></br>
                        <div className='infoPannelFooter'>
                            <div className='stakeInfo'>Please note that when a nudie is unstaked, the Opensea API can take a few 
                            minutes to reflect this on the website.</div><br></br><br></br>
                            <div className='stakeInfo'>Your asset will be in your wallet but takes a bit longer to be viewed here.</div>
                        </div>
                </div>
            </div>

            <div className='infoPannelBK' style={{
                    display:showAuth
                }}>      
                <div className='infoPannel'>
                    <div className='infoPannelHeader'>
                        <h1>Authorize Staking Contract</h1>
                        <button onClick={hideAuthBox} className='closeInfo fa fa-times'>
                        </button>
                    </div>
                    <div className='authPannel'>
                        <div className='stakeInfo'>To use the staking contract, you first need to authorize it for use and wait for it to process.</div>
                        <div className='stakeInfo'>This will allow you to stake / unstake your Nudie from the contract.</div>
                        <Button className='authButton' onClick={AuthorizeContract}>
                            Authorize
                        </Button>
                    </div>
                </div>
            </div>
            <div data-aos="fade-left" className='topContainer'>
                <div className='walletContainer'>
                    {Math.round(walletAmount)}<img src={coin}/>
                    {/* {walletAmount}<img src={coin}/> */}
                </div>
                <Button className='walletConnected' onClick={LogBackIn}>
                    {displayWallet.length > 0 ? (
                    String(displayWallet).substring(0, 4) +
                    "..." +
                    String(displayWallet).substring(39)
                    ) : (
                    <span>Wallet Error</span>
                    )}
                <Route exact path="/Staking" render={() => (
                    logBackIn ? (
                        <Redirect to="/"/>
                    ) : (
                        <Redirect to="/Staking"/>
                    )
                    )}/>
                </Button>
                <Button className='walletConnected' onClick={showInfoBox}>
                    Stake Info
                </Button>
            </div>
            <div className='stakingContainer'>
                <div data-aos="fade-right" className='leftContainer'>
                    <div className='StakingContianer'>
                        <h1>Owned Nudies</h1>
                        <div className='stakingNudieHolder'>
                            <Card handler={setChosenNudie} props={imagesArray}/>
                        </div>
                    </div>
                </div>
                <div data-aos="fade-up" className='middleContainer'>
                    <div className='stakingControls'>
                        <Button className='stakeButton' onClick={StakeNudie}>Stake</Button>
                        <div className='escrowContainer'>
                            <div className='escrowCard'>
                                <div className='escrowImage'>
                                    <img
                                    style={{display : showChosenNudie}} 
                                    src={chosenNudieImage}/>
                                </div>
                                <h4>{chosenNudie}</h4>
                            </div>
                        </div>
                        <Button className='unstakeButton' onClick={UnStakeNudie}>Un-Stake</Button>
                    </div>
                </div>
                <div data-aos="fade-left" className='rightContainer'>
                    <div className='StakingContianer'>
                        <h1>Staked Nudies</h1>
                        <div className='stakedNudieHolder'>
                            <CardsStaked handler={setChosenNudie} props={chosenStakedImage}/>   
                        </div>
                    </div>
                </div>
            </div>
            <div  className='bottomContainer'>
                <div className='leftBottomContainer'>
                    <Button className='stakeAllButton' onClick={StakeAllNudies}>Stake All</Button>
                    <Button className='unstakeAllButton2' onClick={UnStakeAllNudies}>Un-Stake All</Button>
                </div>
                <div className='midBottomContainer'>
                    <div className='tokenEarnedContainer'>
                        Tokens Earned         
                        <div className='tokensEarned'>
                            {tokensToClaim}
                        </div>
                    </div>
                    <Button className='claimTokensButton' onClick={ClaimStakingIncome}>
                        Claim Tokens
                    </Button>
                </div>           
                <div className='rightBottomContainer'>
                    <Button className='unstakeAllButton' onClick={UnStakeAllNudies}>Un-Stake All</Button>
                </div>
            </div>
            <div className='footer '>
            </div>
        </div>
    )
}

export default Staking;
