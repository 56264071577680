import "./Inventory.css"
import React, { useState, useEffect, useRef } from 'react'; 
import { Button } from 'react-bootstrap'
import SignMessageInput from "../../Utils/Blockchain/SignMessage"
import { getCurrentWalletConnected, walletConnectWallet, clearWalletConnectWallet } from "./../../Utils/Wallet/Interact";
import {SendAPIPost, SendInitClosetAPIPost} from "../../Utils/APIs/BackEndAPIs"
import Snackbar from "./../../Utils/Snackbar/Snackbar"

const SnackbarType = {
    success: "success",
    fail: "fail",
};


export const ClosetInitialization = ({prop2, props, handler}) =>{
    const [showInventoryBox, setShowInventoryBox] = useState(prop2);
    const snackbarRef = useRef(null);
    const [snackBarType, SetSnackBarType] = useState(SnackbarType.fail)
    const [snackBarMessage, SetSnackBarMessage] = useState("")

    function hideRemoveInventoryBox(){
        handler("none")
        setShowInventoryBox("none")
    }

    const { toChecksumAddress } = require('ethereum-checksum-address')

    async function closetInitialize(){
        var wallet =  await (await getCurrentWalletConnected()).address
        var checkSumAddress = toChecksumAddress(wallet)
        
        const JSONString = JSON.stringify({ 
            function: "InitCloset",
            WalletAddress: checkSumAddress})

        const singedResponse = await SignMessageInput(JSONString)

        if(singedResponse.signature.length < 1){
            SetSnackBarMessage("Singnature Declined")
            SetSnackBarType(SnackbarType.fail)
            snackbarRef.current.show()                  
        }else{
            const responseStatus = await SendInitClosetAPIPost(singedResponse) 
            
            if(responseStatus == 200){
                SetSnackBarMessage("Request Sent")
                SetSnackBarType(SnackbarType.success)
                snackbarRef.current.show()  
            }
            if(responseStatus == 500){
                const secondResponseStatus = await SendInitClosetAPIPost(singedResponse)  

                if(secondResponseStatus == 500){    
                    SetSnackBarMessage("Request Failed Please Try Again")
                    SetSnackBarType(SnackbarType.fail)
                    snackbarRef.current.show()  
                }
                if(secondResponseStatus == 200){
                    SetSnackBarMessage("Request Sent")
                    SetSnackBarType(SnackbarType.success)
                    snackbarRef.current.show()  
                }
            }                    
        }
    }
  
    useEffect(async () => {
        setShowInventoryBox(prop2)
    });

    return(
        <div className='infoPannelBK' style={{
            display:showInventoryBox
        }}> 
        <Snackbar
            ref={snackbarRef}
            message={snackBarMessage}
            type={snackBarType}
        />
        <div className='inventoryPannel'>
            <div className='inventoryPannelHeader'>
                <h1>Inventory Initialization</h1>
                <button onClick={hideRemoveInventoryBox} className='closeInfo fa fa-times'>
                </button>
            </div>
            <div className='inventoryInitSubSections'>
                <p>Before you can use your inventory, you need to initialize one for your wallet.</p>
                <p>Click the button below then wait a few minutes before attempting to use it.</p>
                <p>DO NOT PROCEED WITHOUT DOING THIS!</p>
            </div>
            <div className='inventoryPannelFooter'>
                <Button className='removeSelectedTraitButton' onClick={closetInitialize}>Initialize</Button>
            </div>
        </div>
    </div>
    )
}


            // for (let i = 3; i < props.length; i++) {
            //     if(props[i].traitType == "Eyes" || props[i].traitType == "Mouth" || props[i].traitType == "Skins" || props[i].traitType == "Background"){
            //         if(i == 3){setDisplay3(true)}
            //         if(i == 4){setDisplay4(true)}
            //         if(i == 5){setDisplay5(true)}
            //         if(i == 6){setDisplay6(true)}
            //         if(i == 7){setDisplay7(true)}
            //     }
            // }